import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import logo from '../../images/logo.svg';
import styled from 'styled-components';

import { NavLink } from "react-router-dom";

function MainBar({ isLogged, onLogin, onLogout }) {

    return <SiteHeader>
        <Row>
            <Col sm={1}><img src={logo} alt="Veja Logo" /></Col>

            {isLogged && <>
                <MenuCol sm={9}>
                    <StyledNavLink activeClassName='is-active' to="/painel">INICIO</StyledNavLink>
                    <StyledNavLink activeClassName='is-active' to="/home">MAPA INTERATIVO</StyledNavLink>
                    <StyledNavLink activeClassName='is-active' to="/produtores">Gestão de Fornecedores</StyledNavLink>
                    <StyledNavLink activeClassName='is-active' to="/associacoes">Gestão de Associações</StyledNavLink>
                    <StyledNavLink activeClassName='is-active' to="/microrregioes">Gestão de Microrregiões</StyledNavLink>
                    <StyledNavLink activeClassName='is-active' to="/custos">Gestao de custos</StyledNavLink>
                </MenuCol>

                <ProfileCol sm={2}>
                    <AnchorButton onClick={onLogout}>Sair</AnchorButton>
                </ProfileCol>
            </>}

        </Row>
    </SiteHeader>;
}

const SiteHeader = styled(Container)`
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: 1300px) {
        max-width: 1450px;
    }
`;

const FlexAligned = styled(Col)`
    display: flex;
    align-items: center;
    color: #333;
`;

const MenuCol = styled(FlexAligned)`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    & a {
        margin: 0 10px;
    }
`;
const ProfileCol = styled(FlexAligned)`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight:700;
    text-transform: uppercase;
`;

const StyledNavLink = styled(NavLink)`
    color: #333;

    &:hover, &:focus {
        color: #333;
    }

    &.is-active:hover {
        text-decoration: none;
    }

    &.is-active {
        font-weight: bold;
    }
`;

const AnchorButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0 10px;
    padding: 0;

    text-transform: uppercase;
    font-weight: 700;
`;

export default MainBar;