import React, { useState } from 'react';

import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

import styled from 'styled-components';

export default function Login({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
        <ForceHeight>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <Card>
                            <Card.Body>
                                <Card.Body>
                                    <Form>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>E-mail</Form.Label>
                                            <Form.Control type="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Senha</Form.Label>
                                            <Form.Control type="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                                        </Form.Group>
                                        
                                        <Button variant="primary" block onClick={()=>onLogin(email, password)}>
                                            Entrar
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </ForceHeight>
    );
}

/* Styles */
const ForceHeight = styled.div`
    min-height: 50vh;
`;