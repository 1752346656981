import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import numeral from 'numeral';
import moment from 'moment';

import AsyncSelect from 'react-select/async';

import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
} from 'react-router-dom';

import { Formik } from 'formik';

import Paginator from '../Paginator';

import styled from 'styled-components';

import { Table, Card, ButtonGroup, Button, Form, InputGroup, FormControl, Row, Col, Modal, Toast, Tab, Tabs } from 'react-bootstrap';

import { getRiscoEscala } from '../../utils';

import { faPlusCircle, faPencilAlt, faTrashAlt, faCheck, faTimes, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const colspan = 12;

const lastYear = moment()/* .subtract(1, 'y') */.year();

async function fetchEntities(page = 1, filters) {

    let url = `${process.env.REACT_APP_SERVER}geo-service/manage/producers?page=${page}`;

    // handle filters
    let urlFilters = [];
    for (let f of Object.keys(filters)) {
        if (!!filters[f]) urlFilters.push(`${f}=${filters[f]}`);
    }

    return await axios.get(`${url}&${urlFilters.join('&')}`);

}

function ManageProducers() {
    const [producers, setProducers] = useState([]);
    const [maxYear, setMaxYear] = useState(lastYear);
    const [paginatorData, setPaginatorData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [filters, _filters] = useState({
        name: false,
        assoc: false,
        cpf: false,
        status: false,
    });

    const history = useHistory();

    const [costPerKilo, setCostPerKilo] = useState(0);

    const [assocs, setAssocs] = useState([]);
    useEffect(() => {
        async function fetchAssocs() {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/manage/assocs`);
            setAssocs(data);
        }

        fetchAssocs();
    }, []);

    useEffect(() => {
        async function fetchProducers(page = 1, filters) {

            const { data: { rows, pages, hasPrevious, hasNext, maxYear, cost } } = await fetchEntities(page, filters);

            setProducers(rows);
            setPaginatorData({ pages, hasPrevious, hasNext });
            setMaxYear(maxYear);
            setCostPerKilo(cost);
        }

        if (currentPage) fetchProducers(currentPage, filters);
    }, [currentPage, filters]);

    const handlePageChange = (page) => {
        // TODO: loading...

        setCurrentPage(page);
    }

    const handleEdit = (gid) => {
        history.push(`/produtores/gerenciar/${gid ? gid : ''}`);
    }

    const handleUpdate = async (newId) => {
        doToast('Os dados do produtor foram gravados!');

        const { data: { rows, pages, hasPrevious, hasNext, maxYear, cost } } = await fetchEntities(currentPage, filters);

        setProducers(rows);
        setPaginatorData({ pages, hasPrevious, hasNext });
        setMaxYear(maxYear);
        setCostPerKilo(cost);

        if (!newId) refresh();
        else history.replace(`/produtores/gerenciar/${newId}`);

    }

    const handleDelete = () => {
        doToast('O produtor foi removido!');
        refresh();
    }

    const handleStatus = () => {
        doToast('O status do produtor foi atualizado!');
        refresh();
    }

    const doToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    }

    const refresh = () => {
        // TODO: best practices
        const currentPageCopy = currentPage;
        setCurrentPage(null);
        setCurrentPage(currentPageCopy);
    }

    const handleFilter = (filterName, value) => {

        let newFilters = {
            ...filters,
            [filterName]: value,
        };

        // regras de incompatibilidade
        if (filterName === 'cpf' && !!value) {
            newFilters.name = false;
            newFilters.assoc = false;
        }
        if (['name', 'assoc'].includes(filterName) && !!value) newFilters.cpf = false;

        _filters(newFilters);
    }

    return <>
        <Switch>

            <Route exact={true} path="/produtores">
                <List
                    currentPage={currentPage}
                    producers={producers}
                    maxYear={maxYear}
                    paginatorData={paginatorData}
                    filters={filters}
                    handlePageChange={handlePageChange}
                    handleEdit={handleEdit}
                    handleFilter={handleFilter}
                    onDelete={handleDelete}
                    onStatus={handleStatus}
                    costPerKilo={costPerKilo}
                    assocs={assocs}
                />
            </Route>

            <Route path="/produtores/gerenciar/:id?">
                <Manage onUpdate={handleUpdate} assocs={assocs} />
            </Route>

        </Switch>

        <StyledToast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
            <Toast.Header>
                <FontAwesomeIcon icon={faCheck} />
                <strong className="mr-auto">Sucesso</strong>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
        </StyledToast>
    </>;
}

function Manage({ onUpdate, assocs }) {
    const [key, setKey] = useState('main');

    let match = useRouteMatch();
    const [gid] = useState(match.params.id);

    const [data, setData] = useState({
        nome: '',
        colocacao: '',
        seringal: '',
        ai_type: 'radius',
        ai_radius: 1500,
        lat: '',
        long: '',
    });

    useEffect(() => {
        async function fetchProducer(gid) {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}`);
            setData(data);
        }

        if (gid) fetchProducer(gid);
    }, [gid]);

    const refresh = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}`);
        setData(data);
    }

    const handleUpdate = () => {
        refresh();
        onUpdate();
    }

    return (
        <Container>
            <Card>
                <Card.Body>

                    {data && <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="main" title="Dados do produtor">
                            <ManageMain gid={gid} data={data} onUpdate={onUpdate} assocs={assocs} />
                        </Tab>

                        {match.params.id && <Tab eventKey="family" title="Família">
                            <ManageFamily gid={gid} data={data} onUpdate={handleUpdate} />
                        </Tab>}

                        {match.params.id && <Tab eventKey="production" title="Produção">
                            <ManageProduction gid={gid} data={data} onUpdate={handleUpdate} />
                        </Tab>}
                    </Tabs>}
                </Card.Body>
            </Card>
        </Container>
    );
}
function ManageFamily({ gid, data, onUpdate }) {
    const history = useHistory();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [removeID, setRemoveID] = useState(false);

    const [showMng, setShowMng] = useState(false);

    const [value, setValue] = useState(null);
    const [relationType, setRelationType] = useState(0);
    const [options, setOptions] = useState(null);

    useEffect(() => {
        async function getFamilyOptions() {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/manage/family_options`);
            setOptions(data);
        }

        getFamilyOptions();
    }, [])

    const startMng = (id) => {

        setRelationType(0);
        setValue(null);

        setShowMng(true);
    }

    const startRemove = (gid) => {
        setRemoveID(gid);
        setShowConfirmation(true);
    }

    const handleRemove = async () => {
        setShowConfirmation(false);

        await axios.delete(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}/family/${removeID}`);

        onUpdate();
    }

    const handleCloseMng = () => {
        setShowMng(false);
        setRelationType(0)
        setValue(null);
    }

    const handleSave = async () => {

        try {
            await axios.post(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}/family`, {
                relationType,
                other_id: value.value,
            });

            onUpdate();
            handleCloseMng();
        } catch (err) { }
    }

    const promiseOptions = (inputValue, callback) => {
        axios
            .get(
                `${process.env.REACT_APP_SERVER}geo-service/producers/search/?filter=${inputValue}&type=family&gid=${gid}`,
            )
            .then(function ({ data }) {
                callback(data);
            });
    };

    const handleFornecedorSeachChange = (selectedOption) => {
        setValue(selectedOption);
    }

    return (
        <Container>
            <Row>
                <Col sm={6}>
                    <StyledTable striped bordered hover>
                        <thead>
                            <tr>
                                <th>Parente</th>
                                <th>Relação (o parente é)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!data.family && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Carregando...</td></tr>}
                            {data.family && data.producao.family === 0 && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Nenhum item encontrado!</td></tr>}
                            {data.family && data.family.map((row) => {

                                return <tr key={row.id}>
                                    <td><div /* style={{ cursor: 'pointer' }} onClick={() => {window.location.href = `/produtores/gerenciar/${row['outro_id']}`}} */>{row['outro_nome']}</div></td>
                                    <td>{row['relacao_nome']}</td>

                                    <CenteredTD>
                                        <OptionsButton variant="outline-dark" size="sm" onClick={() => startRemove(row['id'])}><FontAwesomeIcon icon={faTrashAlt} /></OptionsButton>
                                    </CenteredTD>
                                </tr>
                            })}
                        </tbody>
                    </StyledTable>
                </Col>
                <Col sm={1}><Button variant="outline-dark" onClick={() => startMng()}><FontAwesomeIcon icon={faPlusCircle} /></Button></Col>
            </Row>
            <Row>
                <Col>
                    <SpacedButton variant="outline-dark" onClick={() => history.goBack()}>
                        Cancelar
                    </SpacedButton>
                </Col>
            </Row>

            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de Remoção</Modal.Title>
                </Modal.Header>
                <Modal.Body>Você confirma a exclusão deste dado!?</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={() => setShowConfirmation(false)}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleRemove}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMng} onHide={handleCloseMng}>
                <Modal.Header closeButton>
                    <Modal.Title>Gerenciar familiares</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Parente*</Form.Label>
                                    <div>
                                        <AsyncSelect
                                            loadOptions={promiseOptions}
                                            isClearable
                                            noOptionsMessage={() => 'Nenhum produtor encontrado!'}
                                            loadingMessage={() => 'Buscando...'}
                                            placeholder={'Digite o nome do produtor...'}
                                            value={value}
                                            onChange={handleFornecedorSeachChange}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Relação (o parente é)*</Form.Label>
                                    {!!options && <Form.Control as="select" custom value={relationType} onChange={(e) => setRelationType(e.target.value)}>
                                        <option value={0}> -- Selecione uma relação familiar -- </option>
                                        {options.map(option => <option key={`${option.id}_${option.position}`} value={`${option.id}_${option.position}`}>{option.p_type}</option>)}
                                    </Form.Control>}
                                    {!options && <div>Carregando...</div>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={handleCloseMng}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleSave} disabled={!value || relationType === 0}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}
function ManageProduction({ gid, data, onUpdate }) {
    const history = useHistory();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [removeGID, setRemoveGID] = useState(false);

    const [showMng, setShowMng] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [ano, setAno] = useState(lastYear);
    const [value, setValue] = useState(0);
    const [yearOptions, setYearOptions] = useState([]);

    const startMng = (id) => {

        let yControl;
        if (id) {
            setEditingId(id);

            const current = data.producao.filter(({ id: prodId }) => prodId === id)[0];

            setValue(current.vol_kg);

            yControl = getYearControl(current.ano);

        } else {
            yControl = getYearControl();
        }

        setYearOptions(yControl.options);
        setAno(yControl.selected);

        setShowMng(true);
    }

    const startRemove = (gid) => {
        setRemoveGID(gid);
        setShowConfirmation(true);
    }

    const handleRemove = async () => {
        setShowConfirmation(false);

        await axios.delete(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}/production/${removeGID}`);

        onUpdate();
    }

    const handleCloseMng = () => {
        setShowMng(false);
        setEditingId(null);
        setAno(lastYear);
        setValue(0);
    }

    const handleSave = async () => {
        try {
            const floatValue = parseFloat(value);

            if (editingId) {
                await axios.put(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}/production/${editingId}`, {
                    ano,
                    value: floatValue,
                });
            } else {
                await axios.post(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}/production`, {
                    ano,
                    value: floatValue,
                });
            }

            onUpdate();
            handleCloseMng();
        } catch (err) { }
    }

    const getYearControl = (current) => {
        // lastYear até 2009, sem valores já adicionados, com valor atual (se houver)

        const options = [];

        const jaAdicionados = data.producao ? data.producao.map(prod => prod.ano) : [];
        for (let ano = 2009; ano <= lastYear; ano++) if (!jaAdicionados.includes(ano) || ano === current) options.push(ano);
        options.sort().reverse();

        let selected = options[0];
        if (current) selected = current;

        return {
            options: options,
            selected,
        };
    }

    return (
        <Container>
            <Row>
                <Col sm={6}>
                    <StyledTable striped bordered hover>
                        <thead>
                            <tr>
                                <th>Ano</th>
                                <th>Produção (kg)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!data.producao && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Carregando...</td></tr>}
                            {data.producao && data.producao.length === 0 && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Nenhum item encontrado!</td></tr>}
                            {data.producao && data.producao.sort(perYear).map((row, idx) => {

                                return <tr key={idx}>
                                    <Column type="ano" data={row['ano']} />
                                    <Column type="vol_kg" data={numeral(parseFloat(row['vol_kg'])).format('0.00')} />

                                    <CenteredTD>
                                        <OptionsButton variant="outline-dark" size="sm" onClick={() => startMng(row['id'])}><FontAwesomeIcon icon={faPencilAlt} /></OptionsButton>
                                        <OptionsButton variant="outline-dark" size="sm" onClick={() => startRemove(row['id'])}><FontAwesomeIcon icon={faTrashAlt} /></OptionsButton>
                                    </CenteredTD>
                                </tr>
                            })}
                        </tbody>
                    </StyledTable>
                </Col>
                <Col sm={1}><Button variant="outline-dark" onClick={() => startMng()}><FontAwesomeIcon icon={faPlusCircle} /></Button></Col>
            </Row>
            <Row>
                <Col>
                    <SpacedButton variant="outline-dark" onClick={() => history.goBack()}>
                        Cancelar
                    </SpacedButton>
                </Col>
            </Row>

            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de Remoção</Modal.Title>
                </Modal.Header>
                <Modal.Body>Você confirma a exclusão deste dado!?</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={() => setShowConfirmation(false)}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleRemove}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMng} onHide={handleCloseMng}>
                <Modal.Header closeButton>
                    <Modal.Title>Gerenciar valor de produção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <Row>
                            <Col>
                                {yearOptions && <Form.Group>
                                    <Form.Label>Ano*</Form.Label>
                                    <Form.Control as="select" custom value={ano} onChange={(e) => setAno(e.target.value)}>
                                        {yearOptions.map(year => <option key={year} value={year}>{year}</option>)}
                                    </Form.Control>
                                </Form.Group>}
                                {!yearOptions && <div>Todos os anos foram preenchidos!</div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Valor (kg)*</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="value" value={value} onChange={(e) => setValue(e.target.value)} />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={handleCloseMng}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleSave}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}
function ManageMain({ gid, data, assocs, onUpdate }) {
    const history = useHistory();

    const fileInput = useRef();

    const [micros, setMicros] = useState([]);
    const [saving, setSaving] = useState(false);
    const [conflict, setConflict] = useState(false);

    const [currentAssoc, setCurrentAssoc] = useState(null);

    const [cpfDeduped, setcpfDeduped] = useState('validated');

    useEffect(() => {
        async function fetchMicros() {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/manage/micros/per_assoc/${currentAssoc}`);
            setMicros(data);
        }

        if (currentAssoc) fetchMicros()
        else setMicros([]);

    }, [currentAssoc]);

    useEffect(() => {
        if (data && data.assoc) setCurrentAssoc(data.assoc.id);
    }, [data]);

    const updateMicros = (e) => {
        setCurrentAssoc(e.target.value);
    }

    const validate = async values => {
        const errors = {};

        if (!values.nome) {
            errors.nome = 'O NOME deve ser preenchido';
        }
        if (!values.cpf || values.cpf.length < 14) {
            errors.cpf = 'O CPF deve ser preenchido';
        }
        if (parseInt(values.assoc) === -1) {
            errors.assoc = 'A ASSOCIAÇÃO / COOPERATIVA deve ser preenchida';
        }
        if (parseInt(values.microrregiao) === -1) {
            errors.microrregiao = 'A MICRORREGIÃO deve ser preenchida';
        }
        /* if (!values.colocacao) {
            errors.colocacao = 'A COLOCAÇÃO deve ser preenchida';
        }
        if (!values.seringal) {
            errors.seringal = 'O SERINGAL deve ser preenchido';
        } */
        if (!values.lat) {
            errors.lat = 'A LATITUDE deve ser preenchida';
        }
        if (!values.long) {
            errors.long = 'A LONGITUDE deve ser preenchida';
        }
        if (values.ai_type === 'radius' && !values.ai_radius) {
            errors.ai_radius = 'A ÁREA DE INFLUÊNCIA deve ser preenchida';
        }

        return errors;
    };

    const save = async (saveData) => {
        const formData = new FormData();
        for (let [key, value] of Object.entries(saveData)) formData.append(key, value);

        setSaving(true);

        const { data } = await axios.post(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid ? gid : ''}`, formData);
        if (!!data.error) {
            if (data.code === 'coords_shape_conflict') setConflict(true);
        } else {
            const { gid: savedId } = data;
            onUpdate(!gid ? savedId : null);
        }

        setSaving(false);

    }

    const checkCPFDedup = async (e) => {
        if (e.target.value.length < 14) {
            setcpfDeduped('validated');
            return;
        }

        setcpfDeduped('validating');
        const { data: cpfCheck } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/checkcpf/?cpf=${e.target.value}&gid=${gid ? gid : ''}`, data);

        setcpfDeduped(cpfCheck.error ? 'error' : 'validated');
    }

    return (
        <Container>
            <Formik
                validate={validate}
                onSubmit={save}
                enableReinitialize={true}
                initialValues={{
                    nome: data.nome ? data.nome : '',
                    cpf: data.cpf ? data.cpf : '',
                    colocacao: data.colocacao ? data.colocacao : '',
                    seringal: data.seringal ? data.seringal : '',
                    assoc: data.assoc ? data.assoc.id : -1,
                    microrregiao: data.microrregiao ? data.microrregiao.id : -1,
                    lat: data.lat ? data.lat : '',
                    long: data.long ? data.long : '',
                    ai_type: data.ai_type ? data.ai_type : 'radius',
                    ai_radius: data.ai_radius ? data.ai_radius : '',
                    ai_shapefile: null,
                    ai_shapefile_name: data.ai_shapefile_name ? data.ai_shapefile_name : '',
                }}
            >
                {({
                    setFieldValue,
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                }) => (
                    <Form noValidate onSubmit={(e) => { handleSubmit(e); }} encType='multipart/form-data'>
                        <Row>
                            <Col sm={8}>
                                <Form.Group>
                                    <Form.Label>Nome*</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="nome" placeholder="Digite o NOME do produtor" value={values.nome} onChange={handleChange} isValid={touched.nome && !errors.nome} isInvalid={!!errors.nome} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nome}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>CPF*</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="cpf" placeholder="Digite o CPF do produtor" value={values.cpf} onChange={(e) => { e.target.value = maskCPF(e.target.value); handleChange(e); checkCPFDedup(e); }} isValid={touched.cpf && !errors.cpf && cpfDeduped !== 'error'} isInvalid={cpfDeduped === 'error' || !!errors.cpf} />
                                        <Form.Control.Feedback type="invalid">
                                            {cpfDeduped === 'error' ? 'Este CPF já está cadastrado na base de dados!' : errors.cpf}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>

                                <Form.Group>
                                    <Form.Label>Associação / Cooperativa*</Form.Label>
                                    <InputGroup>

                                        <Form.Control as="select" name="assoc" value={values.assoc} onChange={(e) => { handleChange(e); updateMicros(e); }} isValid={touched.assoc && !errors.assoc} isInvalid={!!errors.assoc} custom>
                                            <option value={-1}> -- Selecione uma Associação / Cooperativa -- </option>
                                            {assocs && assocs.map(({ id, nome }) => <option key={id} value={id}>{nome}</option>)}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.assoc}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>

                                <Form.Group>
                                    <Form.Label>Microrregião*</Form.Label>
                                    <InputGroup>

                                        <Form.Control as="select" name="microrregiao" value={values.microrregiao} onChange={handleChange} isValid={touched.microrregiao && !errors.microrregiao} isInvalid={!!errors.microrregiao} custom>
                                            <option value={-1}> -- Selecione uma Microrregião -- </option>
                                            {micros && micros.map(({ id, nome }) => <option key={id} value={id}>{nome}</option>)}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.microrregiao}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Colocação</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="colocacao" placeholder="Digite o COLOCAÇÃO do produtor" value={values.colocacao} onChange={handleChange} isValid={touched.colocacao && !errors.colocacao} isInvalid={!!errors.colocacao} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.colocacao}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Seringal</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="seringal" placeholder="Digite o SERINGAL do produtor" value={values.seringal} onChange={handleChange} isValid={touched.seringal && !errors.seringal} isInvalid={!!errors.seringal} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.seringal}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Latitude* (Y)</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="lat" type="number" placeholder="Digite a LATITUDE do produtor" value={values.lat} onChange={(e) => { handleChange(e); setConflict(false) }} isValid={touched.lat && !errors.lat} isInvalid={!!errors.lat} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lat}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Longitude* (X)</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="long" type="number" placeholder="Digite a LONGITUDE do produtor" value={values.long} onChange={(e) => { handleChange(e); setConflict(false) }} isValid={touched.long && !errors.long} isInvalid={!!errors.long} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.long}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Tipo de Área de influência*</Form.Label>
                                    <InputGroup>
                                        <Form.Control as="select" name="ai_type" value={values.ai_type} onChange={e => {
                                            if (e.target.value === 'shapefile') fileInput.current.click();
                                            else {
                                                setFieldValue("ai_type", 'radius');
                                                setFieldValue("ai_radius", '');
                                                setFieldValue("ai_shapefile", null);
                                                setConflict(false);
                                            }
                                        }} /* isValid={touched.assoc && !errors.assoc} isInvalid={!!errors.assoc} */ custom>
                                            <option value="none"> -- Selecione o tipo de Área de influência -- </option>
                                            <option value="radius">Raio</option>
                                            <option value="shapefile">Shapefile</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.assoc}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            {values.ai_type === 'radius' && <Col>
                                <Form.Group>
                                    <Form.Label>Raio (metros)*</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="ai_radius" type="number" placeholder="Digite o ÁREA DE INFLUÊNCIA do produtor" value={values.ai_radius} onChange={handleChange} isValid={touched.ai_radius && !errors.ai_radius} isInvalid={!!errors.ai_radius} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ai_radius}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>}
                            {values.ai_type === 'shapefile' && <Col>
                                <Form.Group>
                                    <Form.Label>Arquivo shapefile*</Form.Label>
                                    <div>
                                        {(values.ai_shapefile || values.ai_shapefile_name) && <SpacedButton title="alterar" variant="outline-dark" onClick={() => fileInput.current.click()}>
                                            {values.ai_shapefile?.name || values.ai_shapefile_name || ''}
                                        </SpacedButton>}
                                    </div>
                                    {conflict && <div className='invalid-feedback' style={{ display: 'block' }}>
                                        Há conflito entre as coordenadas e o shape inserido!
                                    </div>}
                                </Form.Group>
                            </Col>}
                        </Row>
                        <Row>
                            <Col>
                                <SpacedButton variant="outline-dark" onClick={() => history.goBack()}>
                                    Cancelar
                                </SpacedButton>
                                <SpacedButton variant="dark" type="submit" disabled={saving || cpfDeduped !== 'validated'}>
                                    Gravar
                                </SpacedButton>
                                {/* <SpacedButton variant="outline-dark" onClick={() => console.log({values})}>
                                    TESTE
                                </SpacedButton> */}
                            </Col>
                        </Row>

                        <input
                            type="file"
                            accept=".shp"
                            ref={fileInput}
                            hidden
                            onChange={e => {
                                if (e.target.files.length) {
                                    setFieldValue("ai_type", 'shapefile');
                                    setFieldValue("ai_radius", '');
                                    setFieldValue("ai_shapefile", e.target.files[0]);
                                    setConflict(false);
                                }
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

let timer = null;
function List({ currentPage, assocs, producers, maxYear, filters, handleFilter, paginatorData, onDelete, onStatus, handleEdit, handlePageChange, costPerKilo }) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [removeGID, setRemoveGID] = useState(false);

    const startRemove = (gid) => {
        setRemoveGID(gid);
        setShowConfirmation(true);
    }

    const handleRemove = async () => {
        setShowConfirmation(false);

        await axios.delete(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${removeGID}`);

        onDelete();
    }

    const toogleStatus = async (gid, status) => {
        await axios.put(`${process.env.REACT_APP_SERVER}geo-service/manage/producers/${gid}/${status === 'ativo' ? 'inativo' : 'ativo'}`);

        onStatus();
    }

    const [name, _name] = useState('');
    const [assoc, _assoc] = useState(0);
    const [cpf, _cpf] = useState('');
    const [status, _status] = useState(0);

    useEffect(() => {
        _name(filters.name || '');
        _assoc(filters.assoc || 0);
        _cpf(filters.cpf || '');
        _status(filters.status || 0);
    }, [filters])

    const handleKey = (filter) => (e) => {
        if (e.key !== "Enter" && !!filters[filter]) {
            if (timer) clearTimeout(timer);
            const value = e.target.value;
            timer = setTimeout(() => handleFilter(filter, value), 1000); // debounce
        };

        if (e.key === "Enter") handleFilter(filter, e.target.value);
    }

    return (<Container>
        <Card>
            <Controls>
                <Row>
                    <Col sm={3}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Filtrar por nome</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl name="filter" value={name} onChange={(e) => _name(e.target.value)} onKeyUp={handleKey('name')} />
                            <InputGroup.Append>
                                {!!filters.name && <Button variant="outline-dark" onClick={() => handleFilter('name', false)}><FontAwesomeIcon icon={faTimes} /></Button>}
                                {!filters.name && <Button variant="outline-dark" onClick={() => handleFilter('name', name)}>Filtrar</Button>}
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    <Col sm={3}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Filtrar por associação</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" value={assoc} onChange={(e) => { _assoc(e.target.value); handleFilter('assoc', e.target.value === '0' ? false : e.target.value) }} >
                                <option value="0">Todas</option>
                                {assocs && assocs.map(({ id, nome }) => <option key={id} value={id}>{nome}</option>)}
                            </Form.Control>
                        </InputGroup>
                    </Col>

                    <Col sm={3}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Filtrar por CPF</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl name="filter" value={cpf} onChange={(e) => _cpf(maskCPF(e.target.value))} onKeyUp={handleKey('cpf')} />
                            <InputGroup.Append>
                                {!!filters.cpf && <Button variant="outline-dark" onClick={() => { handleFilter('cpf', false) }}><FontAwesomeIcon icon={faTimes} /></Button>}
                                {!filters.cpf && <Button variant="outline-dark" onClick={() => handleFilter('cpf', cpf)}>Filtrar</Button>}
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    <Col sm={2}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Status</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" value={status} onChange={(e) => { _status(e.target.value); handleFilter('status', e.target.value === '0' ? false : e.target.value) }}>
                                <option value="0">Todos</option>
                                <option value="ativo">Ativos</option>
                                <option value="inativo">Inativos</option>
                            </Form.Control>
                        </InputGroup>
                    </Col>
                    <Col sm={1}>
                        <Button variant="outline-dark" onClick={() => handleEdit()}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                    </Col>
                </Row>
                <Row>
                    <PageCol sm={12}>
                        <PaginatorContainer><Paginator {...paginatorData} currentPage={currentPage} onChangePage={handlePageChange} /></PaginatorContainer>
                    </PageCol>
                </Row>
            </Controls>
        </Card>


        <Card>
            <Card.Body>
                <StyledTable striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Risco</th>
                            <th>Seringal</th>
                            <th>Colocação</th>
                            <th>Associação / Cooperativa</th>
                            <th>Microrregião</th>
                            <th>Município</th>
                            <th>Área de Proteção</th>
                            <th>Prod. {maxYear} (kg)</th>
                            <th>Prod. {maxYear} (R$)</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!producers && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Carregando...</td></tr>}
                        {producers && producers.length === 0 && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Nenhum item encontrado!</td></tr>}
                        {producers && producers.map((row, idx) => {

                            const producaoAno = row.producao;
                            let production = 0;
                            if (producaoAno) production = producaoAno ? producaoAno.vol_kg : 0;

                            return <StyledTR key={idx} className={row['status'] === 'inativo' ? 'inactive' : ''}>
                                <Column type="nome" data={row['nome']} />
                                <Column type="cpf" data={row['cpf']} />
                                <Column type="risco" data={getRiscoEscala(row['risco'])} />
                                <Column type="seringal" data={row['seringal']} />
                                <Column type="colocacao" data={row['colocacao']} />
                                <Column type="associacao" data={row['assoc']['nome']} />
                                <Column type="microrregiao" data={row['microrregiao']['nome']} />
                                <Column type="municipio" data={row['municipio']} />
                                <Column type="ap" data={row['ap'] ? row['ap']['nome'] : 'Fora de área de proteção'} />
                                <Column type="production" data={numeral(parseFloat(production)).format('0.00')} />
                                <Column type="production_value" data={{ production, costPerKilo }} />

                                <CenteredTD>
                                    <ButtonGroup aria-label="Basic example">
                                        <Button variant="outline-dark" size="sm" onClick={() => handleEdit(row['gid'])}><FontAwesomeIcon icon={faPencilAlt} /></Button>
                                        <Button variant="outline-dark" size="sm" onClick={() => startRemove(row['gid'])}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                                        <Button variant="outline-dark" size="sm" onClick={() => toogleStatus(row['gid'], row['status'])}><FontAwesomeIcon icon={row['status'] === 'ativo' ? faToggleOn : faToggleOff} /></Button>
                                    </ButtonGroup>
                                </CenteredTD>
                            </StyledTR>
                        })}
                    </tbody>
                </StyledTable>
            </Card.Body>
        </Card>

        <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmação de Remoção</Modal.Title>
            </Modal.Header>
            <Modal.Body>Você confirma a exclusão deste produtor!?</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={() => setShowConfirmation(false)}>
                    Cancelar
                </Button>
                <Button variant="dark" onClick={handleRemove}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    </Container>);
}

function transformations(field, data) {
    switch (field) {
        case 'production_value':
            let value = 0;
            if (data.costPerKilo) value = data.production * data.costPerKilo;
            return `R$ ${numeral(parseFloat(value)).format('0.00')}`;
        default:
            return data;
    }
}
function Column(props) {
    return <td>
        {transformations(props.type, props.data)}
    </td>;
}

function maskCPF(cpf) {
    const cleanCPF = cpf.replace(/[^0-9]/g, ''); //cpf.replace(/\./g, '').replace(/-/, '').replace(/ /, '').replace(/[a-zA-Z]/, '');
    let maskedCPF = '';

    for (let idx = 0; idx < cleanCPF.length; idx++) {
        if (idx === 3 || idx === 6) maskedCPF += '.';
        if (idx === 9) maskedCPF += '-';
        maskedCPF += cleanCPF[idx];
    }

    return maskedCPF;
}

function perYear(a, b) {
    if (a.ano > b.ano) return -1;
}

/* Styles */

const Container = styled.div`
    margin: 20px auto;
    max-width: 90vw;
    max-heigth: 80vh;
`;
const StyledTable = styled(Table)`
    width: 100%;
`;

const CenteredTD = styled.td`
    text-align: center;
`;
const OptionsButton = styled(Button)`
    margin: 0 5px;
`;

const Controls = styled(Card.Body)`
    position: relative;
`;

const PaginatorContainer = styled.div``;

const SpacedButton = styled(Button)`
    margin-right: 10px;
`;

const StyledToast = styled(Toast)`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;

    & .toast-header {
        background-color: black;
        color: #fff;
    }

    & .toast-header strong {
        margin: 3px 4px;
    }
`;

const StyledTR = styled.tr`
    &.inactive, &.inactive:hover {
        color: #bbb !important;
    }
`;

const PageCol = styled(Col)`
    margin-top: 15px;
    text-align: center;
`;

export default ManageProducers;