import React, { useState, Fragment, useEffect } from 'react';

import axios from 'axios';

import { GeoJSON } from 'react-leaflet';

// import { getRiscoEscala } from '../../utils';

function AreasInfluencia(props) {
  const [areasInfluencia, setAreasInfluencia] = useState([]);

  useEffect(() => {
    loadAreasInfluencia();
  }, [props.mode]);

  const loadAreasInfluencia = async () => {
    setAreasInfluencia([]);
    // console.log('loadAreas');
    // TODO: TRY/CATCH
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}geo-service/areas-influencia`,
    );
    setAreasInfluencia(data);
  };

  const handleAdd = (microId, me) => {
    if (props.flyToRegion && props.flyToRegion === microId) {
      props.fly(microId, me.getBounds());
    }
  };

  return (
    <Fragment>
      {areasInfluencia.map((areas) => {
        const {
          microId,
          // geojson: {
          // properties: { risco_r },
          // },
        } = areas;

        let style = () => {
          return {
            fillColor: props.style.fillColor,
            fillOpacity: 0.3,
            color: props.style.color,
            opacity: 1,
          };
        };

        if (props.microId !== null && props.microId !== areas.geojson?.id) {
          style = () => {
            return {
              fillColor: props.style.fillColor,
              fillOpacity: 0.2,
              color: props.style.color,
              opacity: 0.2,
            };
          };
        }

        return (
          <GeoJSON
            key={microId}
            data={areas.geojson}
            style={style}
            /* onmouseover={()=>console.log('hover AI', microId)} */
            onclick={(e) => {
              /* console.log(e);  */ props.onInfo(microId);
            }}
            onAdd={({ target }) => handleAdd(microId, target)}
          ></GeoJSON>
        );
      })}
    </Fragment>
  );
}

export default AreasInfluencia;
