const legendCommon = [
  {
    id: 3,
    name: 'Caet',
    fillColor: [128,51,0],
    color: '#000',
    circle: true,
  },
  {
    id: 1,
    name: 'Coopaeb',
    fillColor: [32,82,11],
    color: '#000',
    circle: true,
  },
  {
    id: 10,
    name: 'Cooperiaco',
    fillColor: [22,138,80],
    color: '#000',
    circle: true,
  },
  {
    id: 12,
    name: 'CoopBonal',
    fillColor: [87,140,255],
    color: '#000',
    circle: true,
  },
  {
    id: 13,
    name: 'Cooperlibertadora',
    fillColor: [70,50,250],
    color: '#000',
    circle: true,
  },
  {
    id: 14,
    name: 'Copasfe',
    fillColor: [235,56,255],
    color: '#000',
    circle: true,
  },
  {
    id: 15,
    name: 'ASROP',
    fillColor: [255,61,119],
    color: '#000',
    circle: true,
  },
  {
    id: 16,
    name: 'APAC. J. G',
    fillColor: [255,158,66],
    color: '#000',
    circle: true,
  },
  {
    id: 17,
    name: 'AMORARR',
    fillColor: [203,255,71],
    color: '#000',
    circle: true,
  },
  {
    id: 18,
    name: 'ACOSPER',
    fillColor: [77,255,91],
    color: '#000',
    circle: true,
  },
  {
    id: 19,
    name: 'ASPROC',
    fillColor: [82,255,235],
    color: '#000',
    circle: true,
  },
  {
    id: 20,
    name: 'AGUAPÉ',
    fillColor: [84,207,255],
    color: '#000',
    circle: true,
  },
  {
    id: 21,
    name: 'APBU',
    fillColor: [40,54,7],
    color: '#000',
    circle: true,
  },
  {
    id: 22,
    name: 'ASMOBRI',
    fillColor: [16,110,26],
    color: '#000',
    circle: true,
  },
  {
    id: 25,
    name: 'COOPERGUAJARÁ',
    fillColor: [28,166,152],
    color: '#000',
    circle: true,
  },
  {
    id: 26,
    name: 'AAEPRI',
    fillColor: [42,96,222],
    color: '#000',
    circle: true,
  },
  {
    id: 28,
    name: 'COOPEX',
    fillColor: [154,54,255],
    color: '#000',
    circle: true,
  },
  {
    id: 32,
    name: 'ASAEX',
    fillColor: [255,59,196],
    color: '#000',
    circle: true,
  },
  {
    id: 4,
    name: 'Coapex',
    fillColor: [255,80,64],
    color: '#000',
    circle: true,
  },
  {
    id: 6,
    name: 'Coopercintra',
    fillColor: [255,233,69],
    color: '#000',
    circle: true,
  },
  {
    id: 7,
    name: 'Coopersonhos',
    fillColor: [131,255,74],
    color: '#000',
    circle: true,
  },
  {
    id: 8,
    name: 'Cooperxapuri',
    fillColor: [79,255,164],
    color: '#000',
    circle: true,
  },
];

export const producersConf = {
  style: {
    radius: 8,
    fillColor: (param) => {

      const found = legendCommon.find(l => l.id === param);
      if(!found) return 'black';

      const [r,g,b] = found.fillColor;
      return `rgb(${r},${g},${b})`;
    },
    color: (mode, param) => {
      if (mode === 0) {
        return '#000';
      } else {
        switch (param) {
          case 'ALTO':
            return '#ff0000';
          case 'BAIXO':
            return '#00ff00';
          default:
            return 'black';
        }
      }
    },
    weight: (mode) => {
      if (mode === 0) {
        return 1;
      } else {
        return 2;
      }
    },
    opacity: 1,
    fillopacity: 0.8,
    fillColorMicro: (param) => {
      switch (param) {
        case 1:
        case 'Médio Rio Envira':
          return '#fda49a';
        case 2:
        case 'Paraná do Ouro':
          return '#fdbcb5';
        case 3:
        case 'Rio Tarauacá - Jordão':
          return '#bebada';
        case 4:
        case 'Cruzeiro do Sul & Rodrigues Alves':
          return '#fccde5';
        case 5:
        case 'Alto Rio Tejo':
          return '#d9d9d9';
        case 6:
        case 'Igarapé Grajaú':
          return '#80b1d3';
        case 7:
        case 'Resex Chico Mendes - Assis Brasil & Brasileia':
          return '#8dd3c7';
        case 8:
        case 'Alto Rio Envira':
          return '#d81b08';
        case 9:
        case 'Baixo Rio Jurupari':
          return '#fb8072';
        case 10:
        case 'Rio Gregório':
          return '#857fba';
        case 11:
        case 'Igarapé Cruzeiro do Vale':
          return '#498fc1';

        case 13:
        case 'FLONAs e Resex Cazumbá Iracema – Sena Madureira':
          return '#ffffb3';

        case 15:
        case 'Igarapé Natal':
          return '#a4c8e1';
        case 16:
        case 'Rio Bagé':
          return '#b3b3b3';
        case 17:
        case 'Alto Rio Jurupari':
          return '#f94e3b';

        case 19:
        case 'Rio Muru':
          return '#a099c9';
        case 20:
        case 'Resex Chico Mendes - Xapuri ':
          return '#bc80bd';

        case 22:
        case 'Igarapé José de Melo':
          return '#7E789E';
        case 23:
        case 'Igarapé São Salvador':
          return '#AFD778';
        case 24:
        case 'Rio Tejo':
          return '#87CF23';
        case 25:
        case 'Antimari ':
          return '#DEDE7D';
        case 26:
        case 'Campo Alegre':
          return '#916300';
        case 27:
        case 'Limeira':
          return '#D18F00';
        case 28:
        case 'PAD PEDRO PEIXOTO':
          return '#C78258';
        case 29:
        case 'PDS BONAL ':
          return '#FCA570';
        case 30:
        case 'Rio Iaco':
          return '#FFFFDE';
        case 31:
        case 'Rio Purus':
          return '#919191';
        case 32:
        case 'Talismã':
          return '#C29E4C';
        case 33:
        case 'Transacreana':
          return '#E8BE5A';
        default:
          // console.log('BLACK MICRO', param);
          return 'black';
      }
    },
  },
  legend: [
    legendCommon.map(l => {
      const [r,g,b] = l.fillColor;
      return {...l, fillColor: `rgba(${r},${g},${b}, 0.8)`};
    }),
    [
      { name: 'Alto Risco', fillColor: '#fff', color: '#ff0000', circle: true },
      {
        name: 'Baixo Risco',
        fillColor: '#fff',
        color: '#00ff00',
        circle: true,
      },
    ],
  ],
};

export const areaConf = {
  style: {
    fillColor: 'blue',
    color: 'blue',
  },
  legend: [
    {
      name: 'Área de Influência',
      fillColor: 'rgba(0,0,255,0.2)',
      color: 'blue',
      circle: false,
    },
  ],
};

export const hidroConf = {
  style: null,
  legend: [
    {
      name: 'Hidrografia',
      fillColor: 'rgba(155,228,255,0.3)',
      color: '#9BE4FF',
    },
  ],
};

export const protecteAreasConf = {
  style: null,
  legend: [
    {
      name: 'UC de Proteção Integral',
      fillColor: 'rgba(115,178,115,0.3)',
      color: '#73b273',
    },
    {
      name: 'UC de Uso Sustentável',
      fillColor: 'rgba(171,205,102,0.3)',
      color: '#ABCD66',
    },
    {
      name: 'Território Indígena',
      fillColor: 'rgba(245,202,122,0.3)',
      color: '#F5CA7A',
    },
    {
      name: 'Assentamento Rural',
      fillColor: 'rgba(168,112,0,0.3)',
      color: '#a87000',
    },
  ],
};

export const municipalityConf = {
  style: null,
  legend: [{ name: 'Municipios', fillColor: 'none', color: 'black' }],
};
