import React, { useEffect, useState } from 'react';

import axios from 'axios';

import numeral from 'numeral';
import moment from 'moment';

import { Row, Col, Card, Form, InputGroup, Button, Table, Modal } from 'react-bootstrap';

import styled from 'styled-components';

import { faPlusCircle, faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const lastYear = moment()/* .subtract(1, 'y') */.year();

const colspan = 3;

export default () => {
    const [costs, setCosts] = useState(0);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [removeGID, setRemoveGID] = useState(false);

    const [showMng, setShowMng] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [ano, setAno] = useState(lastYear);
    const [value, setValue] = useState(0);
    const [yearOptions, setYearOptions] = useState([]);

    useEffect(() => {
        async function fetchCosts() {
            // TODO: loading...

            // TODO: TRY/CATCH

            let url = `${process.env.REACT_APP_SERVER}geo-service/manage/costs`;
            const { data: { rows } } = await axios.get(url);

            setCosts(rows);
        }

        fetchCosts();
    }, []);

    const refresh = async () => {
        const { data: { rows } } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/manage/costs`);
        setCosts(rows);
    }

    const startMng = (id) => {

        let yControl;
        if (id) {
            setEditingId(id);

            const current = costs.filter(({ ano }) => ano === id)[0];

            setValue(current.valor);

            yControl = getYearControl(current.ano);

        } else {
            yControl = getYearControl();
        }

        setYearOptions(yControl.options);
        setAno(yControl.selected);

        setShowMng(true);
    }

    const startRemove = (gid) => {
        setRemoveGID(gid);
        setShowConfirmation(true);
    }

    const handleRemove = async () => {
        setShowConfirmation(false);

        await axios.delete(`${process.env.REACT_APP_SERVER}geo-service/manage/costs/${removeGID}`);

        refresh();
    }

    const handleCloseMng = () => {
        setShowMng(false);
        setEditingId(null);
        setAno(lastYear);
        setValue(0);
    }

    const handleSave = async () => {
        try {
            const floatValue = parseFloat(value);

            await axios.put(`${process.env.REACT_APP_SERVER}geo-service/manage/costs`, {
                ano,
                value: floatValue,
            });

            handleCloseMng();
            refresh();
        } catch (err) { }
    }

    const getYearControl = (current) => {
        // lastYear até 2009, sem valores já adicionados, com valor atual (se houver)

        const options = [];

        const jaAdicionados = costs ? costs.map(prod => prod.ano) : [];
        for (let ano = 2009; ano <= lastYear; ano++) if (!jaAdicionados.includes(ano) || ano === current) options.push(ano);
        options.sort().reverse();

        let selected = options[0];
        if (current) selected = current;

        return {
            options: options,
            selected,
        };
    }

    return (
        <Container>
            <Card>
                <Card.Body>
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <StyledTable striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Ano</th>
                                            <th>Valor (R$)</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!costs && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Carregando...</td></tr>}
                                        {!!costs && costs.length === 0 && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Nenhum item encontrado!</td></tr>}
                                        {!!costs && costs.sort(perYear).map((row, idx) => {

                                            return <tr key={idx}>
                                                <Column type="ano" data={row['ano']} />
                                                <Column type="valor" data={numeral(parseFloat(row['valor'])).format('0.00')} />

                                                <CenteredTD>
                                                    <OptionsButton variant="outline-dark" size="sm" onClick={() => startMng(row['ano'])}><FontAwesomeIcon icon={faPencilAlt} /></OptionsButton>
                                                    <OptionsButton variant="outline-dark" size="sm" onClick={() => startRemove(row['ano'])}><FontAwesomeIcon icon={faTrashAlt} /></OptionsButton>
                                                </CenteredTD>
                                            </tr>
                                        })}
                                    </tbody>
                                </StyledTable>
                            </Col>
                            <Col sm={1}><Button variant="outline-dark" onClick={() => startMng()}><FontAwesomeIcon icon={faPlusCircle} /></Button></Col>
                        </Row>

                        <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmação de Remoção</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Você confirma a exclusão deste dado!?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-dark" onClick={() => setShowConfirmation(false)}>
                                    Cancelar
                                </Button>
                                <Button variant="dark" onClick={handleRemove}>
                                    Confirmar
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showMng} onHide={handleCloseMng}>
                            <Modal.Header closeButton>
                                <Modal.Title>Gerenciar custo por kilo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <Row>
                                        <Col>
                                            {yearOptions && <Form.Group>
                                                <Form.Label>Ano*</Form.Label>
                                                <Form.Control as="select" custom value={editingId} onChange={(e) => setAno(e.target.value)}>
                                                    {yearOptions.map(year => <option key={year} value={year}>{year}</option>)}
                                                </Form.Control>
                                            </Form.Group>}
                                            {!yearOptions && <div>Todos os anos foram preenchidos!</div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Valor (R$/kg)*</Form.Label>
                                                <InputGroup>
                                                    <Form.Control name="value" value={value} onChange={(e) => setValue(e.target.value)} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-dark" onClick={handleCloseMng}>
                                    Cancelar
                                </Button>
                                <Button variant="dark" onClick={handleSave}>
                                    Confirmar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Container>

                </Card.Body>
            </Card>
        </Container>
    );
}

function Column(props) {
    return <td>
        {transformations(props.type, props.data)}
    </td>;
}

function transformations(field, data) {
    switch (field) {
        default:
            return data;
    }
}

function perYear(a, b) {
    if (a.ano > b.ano) return -1;
}

/* Styles */

const Container = styled.div`
    margin: 20px auto;
    max-width: 90vw;
    max-heigth: 80vh;
`;

const StyledTable = styled(Table)`
    width: 100%;
`;

const CenteredTD = styled.td`
    width: 200px;
    text-align: center;
`;

const OptionsButton = styled(Button)`
    margin: 0 5px;
`;