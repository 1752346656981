import React, { useState, useEffect } from 'react';

import axios from 'axios';

import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
} from 'react-router-dom';

import { Formik } from 'formik';

import Paginator from '../Paginator';

import styled from 'styled-components';

import { Table, Card, ButtonGroup, Button, Form, InputGroup, FormControl, Row, Col, Toast, Tab, Tabs } from 'react-bootstrap';


import { faPlusCircle, faPencilAlt, faCheck, faTimes, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const colspan = 12;

const fetchEntities = async (page = 1, filter = '') => {
    let url = `${process.env.REACT_APP_SERVER}geo-service/manage/associations?page=${page}`;
    if (filter) url = `${url}&filter=${filter}`;
    return await axios.get(url);
}

function ManageAssociations() {
    const [associations, setAssociations] = useState([]);
    const [paginatorData, setPaginatorData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [filtering, setFiltering] = useState(null);

    const history = useHistory();

    useEffect(() => {
        async function fetchAssociations(page = 1, filter = '') {

            const { data: { rows, pages, hasPrevious, hasNext } } = await fetchEntities(page, filter);

            setAssociations(rows);
            setPaginatorData({ pages, hasPrevious, hasNext });
        }

        if (currentPage) fetchAssociations(currentPage, filtering);
    }, [currentPage, filtering]);

    const handlePageChange = (page) => {
        // TODO: loading...

        setCurrentPage(page);
    }

    const handleEdit = (id) => {
        history.push(`/associacoes/gerenciar/${id ? id : ''}`);
    }

    const handleUpdate = async (newId) => {
        doToast('Os dados da associação foram gravados!');

        const { data: { rows, pages, hasPrevious, hasNext } } = await fetchEntities(currentPage, filtering);

        setAssociations(rows);
        setPaginatorData({ pages, hasPrevious, hasNext });

        if (!newId) refresh();
        else history.replace(`/associacoes/gerenciar/${newId}`);

    }

    const handleDelete = () => {
        // SE ESTIVER VAZIA

        // doToast('A associação foi removida!');
        // refresh();
    }

    const doToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    }

    const refresh = () => {
        // TODO: best practices
        const currentPageCopy = currentPage;
        setCurrentPage(null);
        setCurrentPage(currentPageCopy);
    }

    const handleFilter = (filter, type) => {
        setCurrentPage(1);
        setFiltering(filter);
    }

    const handleStatus = () => {
        doToast('O status da associação foi atualizado!');
        refresh();
    }

    return <>
        <Switch>

            <Route exact={true} path="/associacoes">
                <List
                    currentPage={currentPage}
                    associations={associations}
                    paginatorData={paginatorData}
                    filtering={filtering}
                    handlePageChange={handlePageChange}
                    handleEdit={handleEdit}
                    handleFilter={handleFilter}
                    onStatus={handleStatus}
                    onDelete={handleDelete}
                />
            </Route>

            <Route path="/associacoes/gerenciar/:id?">
                <Manage onUpdate={handleUpdate} />
            </Route>

        </Switch>

        <StyledToast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
            <Toast.Header>
                <FontAwesomeIcon icon={faCheck} />
                <strong className="mr-auto">Sucesso</strong>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
        </StyledToast>
    </>;
}

function Manage({ onUpdate }) {
    const [key, setKey] = useState('main');

    let match = useRouteMatch();
    const [id] = useState(match.params.id);

    const [data, setData] = useState({
        nome: '',
    });

    useEffect(() => {
        async function fetchAssociation(id) {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/manage/associations/${id}`);
            setData(data);
        }

        if (id) fetchAssociation(id);
    }, [id]);

    return (
        <Container>
            <Card>
                <Card.Body>

                    {data && <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="main" title="Dados da associação">
                            <ManageMain id={id} data={data} onUpdate={onUpdate} />
                        </Tab>

                    </Tabs>}
                </Card.Body>
            </Card>
        </Container>
    );
}
function ManageMain({ id, data, onUpdate }) {
    const history = useHistory();

    const [saving, setSaving] = useState(false);

    const validate = async values => {
        const errors = {};

        if (!values.nome) {
            errors.nome = 'O NOME deve ser preenchido';
        }

        return errors;
    };

    const save = async (data) => {
        setSaving(true);

        const { data: { savedId } } = await axios.post(`${process.env.REACT_APP_SERVER}geo-service/manage/associations/${id ? id : ''}`, data);

        setSaving(false);

        onUpdate(!id ? savedId : null);
    }

    return (
        <Container>
            <Formik
                validate={validate}
                onSubmit={save}
                enableReinitialize={true}
                initialValues={{
                    nome: data.nome ? data.nome : '',
                    color: data.color || '#000000'
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                }) => (
                    <Form noValidate onSubmit={(e) => { handleSubmit(e); }}>
                        <Row>
                            <Col sm={8}>
                                <Form.Group>
                                    <Form.Label>Nome*</Form.Label>
                                    <InputGroup>
                                        <Form.Control name="nome" placeholder="Digite o NOME da associação" value={values.nome} onChange={handleChange} isValid={touched.nome && !errors.nome} isInvalid={!!errors.nome} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nome}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Label htmlFor="exampleColorInput">Escolha uma cor</Form.Label>
                                <Form.Control
                                    type="color"
                                    id="colorInput"
                                    value={values.color}
                                    title="Escolha uma cor"
                                    name="color"
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br />
                                <SpacedButton variant="outline-dark" onClick={() => history.goBack()}>
                                    Cancelar
                                </SpacedButton>
                                <SpacedButton variant="dark" type="submit" disabled={saving}>
                                    Gravar
                                </SpacedButton>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

function List({ currentPage, associations, filtering, filteringType, paginatorData, onDelete, onStatus, handleEdit, handleFilter, handlePageChange }) {
    // const [showConfirmation, setShowConfirmation] = useState(false);
    // const [removeID, setRemoveID] = useState(false);

    const [filter, setFilter] = useState((filteringType === 'name' && filtering) || '');


    const toogleStatus = async (id, status) => {
        await axios.put(`${process.env.REACT_APP_SERVER}geo-service/manage/assocs/${id}/${status === 'ativo' ? 'inativo' : 'ativo'}`);

        onStatus();
    }

    return (<Container>
        <Card>
            <Controls>
                <Row>
                    <Col sm={1}><Button variant="outline-dark" onClick={() => handleEdit()}><FontAwesomeIcon icon={faPlusCircle} /></Button></Col>

                    <Col sm={5}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Filtrar por nome</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl name="filter" value={filter} onChange={(e) => setFilter(e.target.value)} />
                            <InputGroup.Append>
                                {filtering && filteringType === 'name' && <Button variant="outline-dark" onClick={() => { setFilter(''); handleFilter('', '') }}><FontAwesomeIcon icon={faTimes} /></Button>}
                                <Button variant="outline-dark" onClick={() => { handleFilter(filter, 'name'); }}>Filtrar</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col sm={6}><PaginatorContainer><Paginator {...paginatorData} currentPage={currentPage} onChangePage={handlePageChange} /></PaginatorContainer></Col>
                </Row>
            </Controls>
        </Card>


        <Card>
            <Card.Body>
                <StyledTable striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!associations && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Carregando...</td></tr>}
                        {associations && associations.length === 0 && <tr><td colSpan={colspan} style={{ textAlign: 'center' }}>Nenhum item encontrado!</td></tr>}
                        {associations && associations.map((row, idx) => {

                            return <StyledTR key={idx} className={row['status'] === 'inativo' ? 'inactive' : ''}>
                                <td type="nome" >{row['nome']}</td>
                                <td width="50px" style={{ backgroundColor: `#${row.color}`}}>{/* #{row.color} */}</td>
                                <CenteredTD>
                                    <ButtonGroup aria-label="Basic example">
                                        <Button variant="outline-dark" size="sm" onClick={() => handleEdit(row['id'])}><FontAwesomeIcon icon={faPencilAlt} /></Button>
                                        <Button variant="outline-dark" size="sm" onClick={() => toogleStatus(row['id'], row['status'])}><FontAwesomeIcon icon={row['status'] === 'ativo' ? faToggleOn : faToggleOff} /></Button>
                                    </ButtonGroup>
                                </CenteredTD>
                            </StyledTR>
                        })}
                    </tbody>
                </StyledTable>
            </Card.Body>
        </Card>

        {/* <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmação de Remoção</Modal.Title>
            </Modal.Header>
            <Modal.Body>Você confirma a exclusão deste produtor!?</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={() => setShowConfirmation(false)}>
                    Cancelar
                </Button>
                <Button variant="dark" onClick={handleRemove}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal> */}
    </Container>);
}

/* Styles */

const Container = styled.div`
    margin: 20px auto;
    max-width: 90vw;
    max-heigth: 80vh;
`;
const StyledTable = styled(Table)`
    width: 100%;
`;

const CenteredTD = styled.td`
    text-align: center;
`;

const Controls = styled(Card.Body)`
    position: relative;
`;

const PaginatorContainer = styled.div`
    position: absolute;
    right: 10px;
`;

const SpacedButton = styled(Button)`
    margin-right: 10px;
`;

const StyledToast = styled(Toast)`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;

    & .toast-header {
        background-color: black;
        color: #fff;
    }

    & .toast-header strong {
        margin: 3px 4px;
    }
`;

const StyledTR = styled.tr`
    &.inactive, &.inactive:hover {
        color: #bbb !important;
    }
`;

export default ManageAssociations;