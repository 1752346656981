import React, { useState, useEffect, useRef, Fragment } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';

import ReactToPrint from 'react-to-print';

import moment from 'moment';

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Tab,
  Tabs,
  Popover,
  OverlayTrigger,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';

import {
  faMap,
  faExclamationCircle,
  faQuestionCircle,
  faLocationArrow,
  faArrowUp,
  faArrowDown,
  faEraser,
  faList,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import classnames from 'classnames';
import numeral from 'numeral';
import Paginator from '../Paginator';

import {
  prepareDataDA,
  prepareDataDAT,
  prepareDataDashDpA,
  getAssocColor,
  getRiscoEscala,
} from '../../utils';

function Dashboard() {
  const [tab, setTab] = useState('desmatamento_anual_total');
  const [countProducers, setCountProducers] = useState('...');
  const [countAssociacoes, setCountAssociacoes] = useState('...');
  const [countMunicipios, setCountMunicipios] = useState('...');
  const [countAps, setCountAps] = useState('...');
  const [listApsCat, setListApsCat] = useState([]);
  const [listMuns, setListMuns] = useState([]);
  const [riscoXfamiliasTable, setRiscoXfamiliasTable] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);

  const [order, setOrder] = useState(
    sessionStorage.getItem('currentOrderMain')
      ? sessionStorage.getItem('currentOrderMain')
      : '',
  );
  const [direction, setDirection] = useState(
    sessionStorage.getItem('currentDirectionMain')
      ? sessionStorage.getItem('currentDirectionMain')
      : 'desc',
  );

  let history = useHistory();

  useEffect(() => {
    async function fetchMainTable() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/main?order=${order}&direction=${direction}`,
      );
      setMainTableData(data);
    }

    async function fetchBigNumbers() {
      const {
        data: { producers, associacoes, municipios, aps, riscoXfamilias },
      } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/big_numbers`,
      );
      setCountProducers(producers);
      setCountAssociacoes(associacoes);
      setCountMunicipios(municipios.total);
      setCountAps(aps.total);
      setListApsCat(aps.categorias);
      setListMuns(municipios.list);
      setRiscoXfamiliasTable(riscoXfamilias);
    }

    fetchMainTable();
    fetchBigNumbers();
  }, [order, direction]);

  const handleChangeOrder = (field) => {
    let newDirection;

    if (field === order) {
      newDirection = direction === 'asc' ? 'desc' : 'asc';
      setDirection(newDirection);
    } else {
      newDirection = field === '' ? 'desc' : 'asc';
      setDirection(newDirection);
      setOrder(field);
    }

    sessionStorage.setItem('currentOrderMain', field);
    sessionStorage.setItem('currentDirectionMain', newDirection);
  };

  const popover = (
    <StyledPopover id="popover-basic">
      <Popover.Title as="h3">Por categoria fundiária</Popover.Title>
      <Popover.Content>
        <Table striped>
          <thead>
            <tr>
              <th>Categoria</th>
              <th>Num. A. Protegidas</th>
              <th>Num. Fornecedores</th>
            </tr>
          </thead>
          <tbody>
            {listApsCat.map(({ categoria, totalAPs, totalProds }) => (
              <tr key={categoria}>
                <td>{categoria}</td>
                <StyledTD>{totalAPs}</StyledTD>
                <StyledTD>{totalProds}</StyledTD>
              </tr>
            ))}
          </tbody>
        </Table>
      </Popover.Content>
    </StyledPopover>
  );

  const popoverMuns = (
    <StyledPopover id="popover-basic">
      <Popover.Title as="h3">Fornecedores por município</Popover.Title>
      <Popover.Content>
        <Table striped>
          <thead>
            <tr>
              <th>Município</th>
              <th>Num. Fornecedores</th>
            </tr>
          </thead>
          <tbody>
            {listMuns.map(({ nome_mun, total }) => (
              <tr key={nome_mun}>
                <td>{nome_mun}</td>
                <StyledTD>{total}</StyledTD>
              </tr>
            ))}
          </tbody>
        </Table>
      </Popover.Content>
    </StyledPopover>
  );

  const popoverRisco = (
    <StyledPopover id="popover-basic">
      <Popover.Title as="h3">Risco</Popover.Title>
      <Popover.Content>
        <Table striped>
          <thead>
            <tr>
              <th>Risco</th>
              <th>Num. Famílias</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ALTO</td>
              <StyledTD>
                {riscoXfamiliasTable ? riscoXfamiliasTable['alto'] : '...'}
              </StyledTD>
            </tr>
            <tr>
              <td>BAIXO</td>
              <StyledTD>
                {riscoXfamiliasTable ? riscoXfamiliasTable['baixo'] : '...'}
              </StyledTD>
            </tr>
          </tbody>
        </Table>
      </Popover.Content>
    </StyledPopover>
  );

  const popoverApresentacao = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Apresentação</Popover.Title>
      <Popover.Content>
        Essa plataforma de monitoramento tem como principal objetivo avaliar o
        risco de desmatamento da cadeia de fornecimento de borracha da V. Fair
        Trade. A plataforma considera as famílias que fornecem
        borracha para a VFT, no Estado do Acre, por meio de associações
        distribuidas ao longo de Unidades de Conservação, Assentamentos Rurais e
        áreas particulares.
        <br />
        <br />A plataforma tem como missão apoiar a gestão da cadeia de
        fornecimento da empresa, indicando as áreas de maior e menor risco de
        desmatamento, além de gerar análises automatizadas referentes aos
        alertas de desmatamento gerados nas regiões de interesse e de atuação da
        VFT.
      </Popover.Content>
    </Popover>
  );

  const popoverNota = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Nota sobre metodologia</Popover.Title>
      <Popover.Content>
        A plataforma de monitoramento utiliza dados oficias de desmatamento
        disponibilizados pelo Sistema PRODES e alertas do Sistema DETER, ambos
        coordenados pelo Instituto Nacional de Pesquisas Espaciais (INPE). A
        plataforma não vincula nem atribui a responsabilidade sobre o
        desmatamento verificado para as famílias extrativistas, mas sim
        apresenta uma análise de risco de desmatamento a partir de uma série
        histórica de dados analisados (2008 – 2020).
      </Popover.Content>
    </Popover>
  );

  const popoverChart1 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Sobre este gráfico</Popover.Title>
      <Popover.Content>
        Desmatamento total anual na “área de influência” da cadeia de
        fornecimento da Veja por Associação (hectares/ano)
      </Popover.Content>
    </Popover>
  );
  const popoverChart2 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Sobre este gráfico</Popover.Title>
      <Popover.Content>
        Desmatamento anual por associação vinculada a cadeia de forneciento da
        Veja (hectares/ano).
        <br />
        <b>Pode haver contagem dupla de desmatamento</b>
      </Popover.Content>
    </Popover>
  );
  const popoverChart3 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Sobre este gráfico</Popover.Title>
      <Popover.Content>
        Média de desmatamento por família fornecedora da Veja (hectares/família)
      </Popover.Content>
    </Popover>
  );
  const popoverChart4 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Sobre este gráfico</Popover.Title>
      <Popover.Content>
        Desmatamento anual nos municipios de atuação da Veja (hectares/ano)
      </Popover.Content>
    </Popover>
  );
  const popoverChart5 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Sobre este gráfico</Popover.Title>
      <Popover.Content>
        Desmatamento anual nas categorias fundiárias de atuação da Veja
        (hectares/ano)
      </Popover.Content>
    </Popover>
  );

  const popoverChart6 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Sobre este gráfico</Popover.Title>
      <Popover.Content>
        Desmatamento anual por Microrregião de "influência" da Veja
        (hectares/ano)
      </Popover.Content>
    </Popover>
  );

  return (
    <StyledContainer>
      <h2>
        PLATAFORMA DE MONITORAMENTO DE RISCO DE DESMATAMENTO NA CADEIA PRODUTIVA
        DA BORRACHA
      </h2>

      <BigNumbersContainer>
        <Row>
          <Col sm={8}>
            <StyledTable striped>
              <thead>
                <tr>
                  <StyledTH
                    style={{ width: '25%' }}
                    onClick={() => handleChangeOrder('assoc')}
                  >
                    Associação/Cooperativa{' '}
                    <OrderMarker
                      field="assoc"
                      order={order}
                      direction={direction}
                    />
                  </StyledTH>
                  <StyledTH
                    style={{ width: '10%' }}
                    onClick={() => handleChangeOrder('num_fornecedores')}
                  >
                    Num. Fornec.{' '}
                    <OrderMarker
                      field="num_fornecedores"
                      order={order}
                      direction={direction}
                    />
                  </StyledTH>
                  <StyledTH
                    style={{ width: '10%' }}
                    onClick={() => handleChangeOrder('risco_alto')}
                  >
                    Risco ALTO{' '}
                    <OrderMarker
                      field="risco_alto"
                      order={order}
                      direction={direction}
                    />
                  </StyledTH>
                  <StyledTH
                    style={{ width: '10%' }}
                    onClick={() => handleChangeOrder('risco_baixo')}
                  >
                    Risco BAIXO{' '}
                    <OrderMarker
                      field="risco_baixo"
                      order={order}
                      direction={direction}
                    />
                  </StyledTH>
                  <StyledTH
                    style={{ width: '20%' }}
                    onClick={() => handleChangeOrder('desm_last_three_years')}
                  >
                    Desmat. 3 anos{' '}
                    <OrderMarker field="desm_last_three_years" order={order} direction={direction} />
                  </StyledTH>
                  <StyledTH
                    style={{ width: '20%' }}
                    onClick={() => handleChangeOrder('desm_accumulated')}
                  >
                    Desmat. acum.{' '}
                    <OrderMarker field="desm_accumulated" order={order} direction={direction} />
                  </StyledTH>
                  <StyledTH
                    style={{ width: '20%' }}
                    onClick={() => handleChangeOrder('')}
                  >
                    % Desmat.{' '}
                    <OrderMarker field="" order={order} direction={direction} />
                  </StyledTH>
                </tr>
              </thead>
            </StyledTable>
            {mainTableData.length !== 0 && (
              <ScrolledData>
                <Table striped>
                  <tbody>
                    {mainTableData &&
                      mainTableData.map(
                        ({
                          assocId,
                          assoc_nome,
                          n_prods,
                          risco_alto_prods,
                          risco_baixo_prods,
                          percent_desm_accum,
                          desm_last_three_years,
                          desm_accumulated,
                        }) => (
                          <tr key={assocId}>
                            <td style={{ width: '25%' }}>{assoc_nome}</td>
                            <td style={{ width: '10%' }}>{n_prods}</td>
                            <td style={{ width: '10%' }}>{risco_alto_prods}</td>
                            <td style={{ width: '10%' }}>
                              {risco_baixo_prods}
                            </td>
                            <td style={{ width: '20%' }}>
                              {numeral(parseFloat(desm_last_three_years)).format(
                                '0,0.00',
                              )}
                            </td>
                            <td style={{ width: '20%' }}>
                              {numeral(parseFloat(desm_accumulated)).format(
                                '0,0.00',
                              )}
                            </td>
                            <td style={{ width: '20%' }}>
                              {numeral(parseFloat(percent_desm_accum)).format(
                                '0,0.00',
                              )}
                            </td>
                          </tr>
                        ),
                      )}
                  </tbody>
                </Table>
              </ScrolledData>
            )}
            {mainTableData.length === 0 && <Loading>
              <Spinner animation="border" role="status">
                <span className="sr-only">Carregando...</span>
              </Spinner>
            </Loading>}
          </Col>
          <Col sm={2}>
            <Row>
              <Col>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="left"
                  overlay={popoverRisco}
                >
                  <Card>
                    <Card.Body>
                      <BNNumber>{countProducers}</BNNumber>
                      <BNText>Famílias</BNText>
                    </Card.Body>
                  </Card>
                </OverlayTrigger>
              </Col>
            </Row>
            <Row>
              <BigNumber number={countAssociacoes} text="Associações / Cooperativas" />
            </Row>
          </Col>
          <Col sm={2}>
            <Row>
              <Col>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="left"
                  overlay={popoverMuns}
                >
                  <Card>
                    <Card.Body>
                      <BNNumber>{countMunicipios}</BNNumber>
                      <BNText>Municípios</BNText>
                    </Card.Body>
                  </Card>
                </OverlayTrigger>
              </Col>
            </Row>
            <Row>
              <Col>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="left"
                  overlay={popover}
                >
                  <Card>
                    <Card.Body>
                      <BNNumber>{countAps}</BNNumber>
                      <BNText>Áreas Protegidas</BNText>
                    </Card.Body>
                  </Card>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>
        </Row>
      </BigNumbersContainer>

      <Row>
        <Col sm={8}>
          <Button variant="dark" block onClick={() => history.push('/home')}>
            <FontAwesomeIcon icon={faMap} /> Acesse o Mapa Interativo
          </Button>
        </Col>
        <Col sm={2}>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popoverApresentacao}
          >
            <Button variant="dark" block>
              <FontAwesomeIcon icon={faExclamationCircle} /> Apresentação
            </Button>
          </OverlayTrigger>
        </Col>
        <Col sm={2}>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popoverNota}
          >
            <Button variant="dark" block>
              <FontAwesomeIcon icon={faExclamationCircle} /> Metodologia
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>

      <TopMargedCard>
        <Card.Body>
          <Card.Title>Dinâmica de desmatamento</Card.Title>
          <Tabs activeKey={tab} onSelect={(k) => setTab(k)}>
            <Tab
              eventKey="desmatamento_anual_total"
              title={
                <TabTitle>
                  Desm. anual total
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverChart1}
                  >
                    <ButtonInTab variant="light">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </ButtonInTab>
                  </OverlayTrigger>
                </TabTitle>
              }
            >
              <TabContainer>
                <DashDAT />
              </TabContainer>
            </Tab>
            <Tab
              eventKey="desmatamento_anual_assoc_agreg"
              title={
                <TabTitle>
                  Desm. por microrregião
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverChart6}
                  >
                    <ButtonInTab variant="light">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </ButtonInTab>
                  </OverlayTrigger>
                </TabTitle>
              }
            >
              <TabContainer>
                <DashDpA />
              </TabContainer>
            </Tab>
            <Tab
              eventKey="desmatamento_anual_assoc"
              title={
                <TabTitle>
                  Desm. anual por associação / cooperativa
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverChart2}
                  >
                    <ButtonInTab variant="light">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </ButtonInTab>
                  </OverlayTrigger>
                </TabTitle>
              }
            >
              <TabContainer>
                <DashDAA />
              </TabContainer>
            </Tab>
            <Tab
              eventKey="desmatamento_anual_medio"
              title={
                <TabTitle>
                  Desm. anual médio por família
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverChart3}
                  >
                    <ButtonInTab variant="light">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </ButtonInTab>
                  </OverlayTrigger>
                </TabTitle>
              }
            >
              <TabContainer>
                <DashDAMF />
              </TabContainer>
            </Tab>
            <Tab
              eventKey="desmatamento_municipio"
              title={
                <TabTitle>
                  Desm. por município
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverChart4}
                  >
                    <ButtonInTab variant="light">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </ButtonInTab>
                  </OverlayTrigger>
                </TabTitle>
              }
            >
              <TabContainer>
                <DashDM />
              </TabContainer>
            </Tab>
            <Tab
              eventKey="desmatamento_catfunda"
              title={
                <TabTitle>
                  Desm. por categoria fundiária
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverChart5}
                  >
                    <ButtonInTab variant="light">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </ButtonInTab>
                  </OverlayTrigger>
                </TabTitle>
              }
            >
              <TabContainer>
                <DashDCF />
              </TabContainer>
            </Tab>
          </Tabs>
        </Card.Body>
      </TopMargedCard>

      <TopMargedCard>
        <Card.Body>
          <Card.Title>Associações fornecedoras da VEJA</Card.Title>
          <DashRank />
        </Card.Body>
      </TopMargedCard>
    </StyledContainer>
  );
}

function DashRank() {
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem('currentRankPage')
      ? parseInt(sessionStorage.getItem('currentRankPage'))
      : 1,
  );
  const [data, setData] = useState(null);
  const [paginatorData, setPaginatorData] = useState(null);

  const [order, setOrder] = useState(
    sessionStorage.getItem('currentOrder')
      ? sessionStorage.getItem('currentOrder')
      : '',
  );
  const [direction, setDirection] = useState(
    sessionStorage.getItem('currentDirection')
      ? sessionStorage.getItem('currentDirection')
      : 'asc',
  );

  const [filter, setFilter] = useState(
    sessionStorage.getItem('currentFilter')
      ? sessionStorage.getItem('currentFilter')
      : '',
  );
  const [filterText, setFilterText] = useState(
    sessionStorage.getItem('currentFilter')
      ? sessionStorage.getItem('currentFilter')
      : '',
  );

  const [showProducers, setShowProducers] = useState(null);
  const [producers, setProducers] = useState([]);

  let history = useHistory();

  useEffect(() => {
    async function fetchData() {
      let filterQuery = '';
      if (filter.length) filterQuery = `&filter=${filter}`;

      const {
        data: { rows, pages, hasPrevious, hasNext },
      } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/rank?page=${currentPage}&order=${order}&direction=${direction}${filterQuery}`,
      );
      setData(rows);

      setPaginatorData({ pages, hasPrevious, hasNext });
    }

    fetchData();
  }, [currentPage, order, direction, filter]);

  useEffect(() => {
    async function fetchProducers(gid) {
      setProducers(null);
      const {
        data: { rows },
      } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/areas/producer/${gid}?page=-1`,
      );
      setProducers(rows);
    }

    if (!showProducers) setProducers([]);
    else {
      fetchProducers(showProducers);
    }
  }, [showProducers]);

  const handlePageChange = (page) => {
    // TODO: loading...
    sessionStorage.setItem('currentRankPage', page);
    setCurrentPage(page);
  };

  const flyToRegion = (gid) => {
    history.push({
      pathname: '/home',
      state: {
        flyToRegion: gid,
      },
    });
  };

  const handleChangeOrder = (field) => {
    let newDirection;

    if (field === order) {
      newDirection = direction === 'asc' ? 'desc' : 'asc';
      setDirection(newDirection);
    } else {
      newDirection = field === '' ? 'desc' : 'asc';
      setDirection(newDirection);
      setOrder(field);
    }

    sessionStorage.setItem('currentOrder', field);
    sessionStorage.setItem('currentDirection', newDirection);
    handlePageChange(1);
  };

  const handleFilter = (filter) => {
    setFilter(filter);
    /* console.log(filter); */
    handlePageChange(1);
    sessionStorage.setItem('currentFilter', filter);
  };

  const handleFilterDebounced = useRef(
    _.debounce(handleFilter, 1000, { leading: false, trailing: true }),
  );

  const handleFilterChange = (e) => {
    const { value } = e.target;

    setFilterText(value);
    handleFilterDebounced.current(value);
  };

  const handleClearFilter = () => {
    handleFilter('');
    setFilterText('');
  };

  const handleChangeList = (gid) => {
    setShowProducers(showProducers === gid ? null : gid);
  };

  const popoverFly = (
    <Popover id="popover-basic">
      <Popover.Content>
        Acessar as informações desta microrregião no mapa interativo.
      </Popover.Content>
    </Popover>
  );
  const popoverList = (
    <Popover id="popover-basic">
      <Popover.Content>
        Listar os fornecedores desta associação.
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <Row>
        <Col sm={6}>
          <Form.Group>
            {/* <Form.Label>Filtrar por associação / cooperativa</Form.Label> */}
            <InputGroup>
              <Form.Control
                name="nome"
                placeholder="Filtre por nome da associação / cooperativa"
                value={filterText}
                onChange={handleFilterChange}
              />
              <InputGroup.Append>
                <Button onClick={handleClearFilter} variant="outline-secondary">
                  <FontAwesomeIcon icon={faEraser} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Table>
        <thead>
          <tr>

            <th>
              <StyledDiv onClick={() => handleChangeOrder('assoc')}>
                Associação / Cooperativa{' '}
                <OrderMarker
                  field="assoc"
                  order={order}
                  direction={direction}
                />
              </StyledDiv>
            </th>

            <th>
              <StyledDiv onClick={() => handleChangeOrder('nprod')}>
                Num. fornecedores{' '}
                <OrderMarker
                  field="nprod"
                  order={order}
                  direction={direction}
                />
              </StyledDiv>
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map(({ gid, n_prods, nome }, idx) => (
              <Fragment key={gid}>
                <StrippedTR
                  key={gid}
                  className={classnames({ even: idx % 2 === 0 })}
                >
                  <td>
                    <AssocFiltered name={nome} filter={filter} />
                  </td>
                  <td>{n_prods}</td>
                  <RankButtons>
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="left"
                      overlay={popoverList}
                    >
                      <Button
                        variant="outline-dark"
                        onClick={() => handleChangeList(gid)}
                      >
                        <FontAwesomeIcon icon={faList} />
                      </Button>
                    </OverlayTrigger>
                  </RankButtons>
                </StrippedTR>

                {showProducers === gid && (
                  <TRList className={classnames({ even: idx % 2 === 0 })}>
                    <td colSpan={4}>
                      <Card>
                        {!producers && (
                          <div>Carregando dados de Fornecedores...</div>
                        )}
                        {producers && (
                          <>
                            <ProducersListLabel>
                              Lista de fornecedores da microrregião
                            </ProducersListLabel>
                            <StyledTable>
                              <thead>
                                <tr>
                                  <th style={{ width: '28%' }}>Nome</th>
                                  <th style={{ width: '20%' }}>Colocação</th>
                                  <th style={{ width: '20%' }}>Seringal</th>
                                  <th style={{ width: '16%' }}>Risco</th>
                                  <th style={{ width: '16%' }}>Microrregião</th>
                                </tr>
                              </thead>
                            </StyledTable>

                            <ScrolledData2>
                              <Table>
                                <tbody>
                                  {producers.map(
                                    ({
                                      gid,
                                      nome,
                                      colocacao,
                                      seringal,
                                      risco,
                                      microId,
                                      micro_nome,
                                    }) => (
                                      <tr key={gid}>
                                        <td style={{ width: '28%' }}>{nome}</td>
                                        <td style={{ width: '20%' }}>
                                          {colocacao}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                          {seringal}
                                        </td>
                                        <td style={{ width: '16%' }}>
                                          {getRiscoEscala(risco)}
                                        </td>
                                        <td style={{ width: '16%' }}>
                                          <div style={{ display: 'flex', gap: '5px' }}>
                                            <div>{micro_nome}</div>
                                            <div>
                                              <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="left"
                                                overlay={popoverFly}
                                              >
                                                <Button
                                                  variant="outline-dark"
                                                  onClick={() => flyToRegion(microId)}
                                                  size='sm'
                                                >
                                                  <FontAwesomeIcon icon={faLocationArrow} />
                                                </Button>
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ),
                                  )}
                                </tbody>
                              </Table>
                            </ScrolledData2>
                          </>
                        )}
                      </Card>
                    </td>
                  </TRList>
                )}
              </Fragment>
            ))}

          {data && data.length === 0 && (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                Nenhum item encontrado para este filtro!
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <PaginatorContainer>
        <Paginator
          {...paginatorData}
          currentPage={currentPage}
          onChangePage={handlePageChange}
        />
      </PaginatorContainer>
    </>
  );
}

function OrderMarker({ field, order, direction }) {
  return (
    <>
      {field === order && (
        <>
          {direction === 'asc' && (
            <OMarker>
              <FontAwesomeIcon icon={faArrowDown} />
            </OMarker>
          )}
          {direction === 'desc' && (
            <OMarker>
              <FontAwesomeIcon icon={faArrowUp} />
            </OMarker>
          )}
        </>
      )}
    </>
  );
}

function AssocFiltered({ name, filter }) {
  let lfilter = filter.toLowerCase();
  const lname = name.toLowerCase();

  let startText = name.slice(0, lname.indexOf(lfilter));
  const endText = name.slice(lname.indexOf(lfilter) + filter.length);

  // capitalize
  if (startText.length)
    startText = startText.charAt(0).toUpperCase() + startText.slice(1);
  else lfilter = lfilter.charAt(0).toUpperCase() + lfilter.slice(1);

  if (filter.length)
    return (
      <>
        {startText}
        <strong>{lfilter}</strong>
        {endText}
      </>
    );
  else return <>{name}</>;
}

const StyledDiv = styled.div`
  cursor: pointer;
`;

const OMarker = styled.span`
  font-size: 0.85em;
  color: #666;
`;

function DashDAT() {
  const [data, setData] = useState(null);

  const componentRef = useRef();

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/desm/?options=total`,
      );
      setData(prepareDataDAT(data));
    }

    fetchData();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <ChartContainer ref={componentRef}>
            <style type="text/css" media="print">{"\
              @page { size: 370mm 160mm;  }\
            "}</style>

            <PrintLabel className="only-print">
              <strong>Desmatamento anual total</strong>
            </PrintLabel>

            {!data && <Loading>
              <Spinner className="graph" animation="border" role="status">
                <span className="sr-only">Carregando...</span>
              </Spinner>
            </Loading>}

            {data && (
              <ResponsiveBar
                className="chart"
                data={data.values}
                keys={data.keys}
                indexBy="ano"
                margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
                padding={0.3}
                groupMode="stacked"
                /* colors={{ scheme: 'greys' }} */
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'desmatamento (hectares)',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableLabel={false}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 120,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 15,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                defs={data.defs}
                fill={data.fill}
                tooltip={({ id, value }) => (
                  <span>
                    <strong>{id}:</strong> {value}
                  </span>
                )}
              />
            )}
          </ChartContainer>

          <ReactToPrint
            className="print-button"
            trigger={() => <PrintButton variant="outline-dark" block>
              <FontAwesomeIcon icon={faPrint} />
            </PrintButton>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    </>
  );
}

function DashDpA() {
  // const lastYear = (new Date()).getFullYear();
  // const lastYear = 2019
  const lastYear = moment().subtract(1, 'y').year();
  const years = [];
  for (let year = 2009; year <= lastYear; year++) years.push(year);

  const [data, setData] = useState(null);
  const [currentYear, setCurrentYear] = useState(lastYear);

  const componentRef = useRef();

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/desm/?options=per_assoc,${currentYear}`,
      );
      setData(prepareDataDashDpA(data));
    }

    fetchData();
  }, [currentYear]);

  /* data && console.log(data.keys, data.values, data.fill, data.defs) */

  return (
    <>
      <Row>
        <Col>
          {
            <Form.Control
              as="select"
              size="lg"
              custom
              value={currentYear}
              onChange={(e) => setCurrentYear(e.target.value)}
            >
              {years.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </Form.Control>
          }
        </Col>
      </Row>

      <Row>
        <Col>
          <ChartContainer ref={componentRef}>
            <style type="text/css" media="print">{"\
              @page { size: 370mm 160mm;  }\
            "}</style>

            <PrintLabel className="only-print">
              <strong>Desmatamento por microrregião</strong> ({currentYear})
            </PrintLabel>

            {!data && <Loading>
              <Spinner className="graph" animation="border" role="status">
                <span className="sr-only">Carregando...</span>
              </Spinner>
            </Loading>}

            {data && (
              <ResponsiveBar
                className="chart"
                data={data.values}
                keys={data.keys}
                indexBy="associacao"
                margin={{ top: 50, right: 250, bottom: 50, left: 60 }}
                padding={0.3}
                groupMode="stacked"
                /* colors={{ scheme: 'greys' }} */
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 1,
                  tickRotation: 30,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'desmatamento (hectares)',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableLabel={false}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 120,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 15,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                defs={data.defs}
                fill={data.fill}
                tooltip={({ id, value }) => (
                  <span>
                    <strong>{id}:</strong> {value}
                  </span>
                )}
              />
            )}
          </ChartContainer>

          <ReactToPrint
            className="print-button"
            trigger={() => <PrintButton variant="outline-dark" block>
              <FontAwesomeIcon icon={faPrint} />
            </PrintButton>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    </>
  );
}

function DashDAMF() {
  const [data, setData] = useState(null);

  const componentRef = useRef();

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/desm/?options=medio_familia`,
      );
      setData(prepareDataDAT(data));
    }

    fetchData();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <ChartContainer ref={componentRef}>
            <style type="text/css" media="print">{"\
              @page { size: 370mm 160mm;  }\
            "}</style>

            <PrintLabel className="only-print">
              <strong>Desmatamento anual m&eacute;dio por família</strong>
            </PrintLabel>

            {!data && <Loading>
              <Spinner className="graph" animation="border" role="status">
                <span className="sr-only">Carregando...</span>
              </Spinner>
            </Loading>}

            {data && (
              <ResponsiveBar
                className="chart"
                data={data.values}
                keys={data.keys}
                indexBy="ano"
                margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
                padding={0.3}
                groupMode="stacked"
                /* colors={{ scheme: 'greys' }} */
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'desmatamento (hectares / família)',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableLabel={false}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 120,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 15,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                defs={data.defs}
                fill={data.fill}

                tooltip={({ id, value, color }) => (
                  <>
                    <strong>
                      {id}:{' '}
                    </strong>
                    {value}
                  </>
                )}
              />
            )}
          </ChartContainer>

          <ReactToPrint
            className="print-button"
            trigger={() => <PrintButton variant="outline-dark" block>
              <FontAwesomeIcon icon={faPrint} />
            </PrintButton>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    </>
  );
}

function DashDAA() {
  const [data, setData] = useState(null);

  const [assocs, setAssocs] = useState([]);
  const [currentAssoc, setCurrentAssoc] = useState(null);

  const componentRef = useRef();

  useEffect(() => {
    async function fetchDetails() {
      const { data: assocs } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/manage/assocs`,
      );
      setAssocs(assocs);
      setCurrentAssoc(assocs[0].id);
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/desm/?options=associacao,${currentAssoc}`,
      );
      setData(data);
    }

    if (currentAssoc) fetchData();
  }, [currentAssoc]);

  const assocById = assocs.filter(
    (assoc) => String(assoc.id) === String(currentAssoc),
  );
  const fillColor = assocById.length
    ? getAssocColor(assocById[0].nome)
    : '#000';

  return (
    <>
      <Row>
        <Col>
          {currentAssoc && (
            <Form.Control
              as="select"
              size="lg"
              custom
              value={currentAssoc}
              onChange={(e) => setCurrentAssoc(e.target.value)}
            >
              {assocs.map(({ id, nome }) => (
                <option value={id} key={id}>
                  {nome}
                </option>
              ))}
            </Form.Control>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <ChartContainer ref={componentRef}>
            <style type="text/css" media="print">{"\
              @page { size: 370mm 160mm;  }\
            "}</style>

            <PrintLabel className="only-print">
              <strong>Desmatamento anual por associação / cooperativa</strong> ({assocById[0]?.nome})
            </PrintLabel>

            {!data && <Loading>
              <Spinner className="graph" animation="border" role="status">
                <span className="sr-only">Carregando...</span>
              </Spinner>
            </Loading>}

            {data && (
              <ResponsiveBar
                className="chart"
                data={prepareDataDA(data)}
                keys={['desmatamento']}
                indexBy="ano"
                margin={{ top: 50, right: 120, bottom: 50, left: 60 }}
                padding={0.3}
                groupMode="grouped"
                colors={[fillColor, '#999']}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'desmatamento (hectares)',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableLabel={false}

              /* tooltip={({ id, value, color }) => (
                      <strong style={{ color }}>
                          {id}: {value}
                      </strong>
                  )} */
              />
            )}
          </ChartContainer>

          <ReactToPrint
            className="print-button"
            trigger={() => <PrintButton variant="outline-dark" block>
              <FontAwesomeIcon icon={faPrint} />
            </PrintButton>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    </>
  );
}

function DashDM() {
  const [data, setData] = useState(null);

  const [muns, setMuns] = useState([]);
  const [currentMun, setCurrentMun] = useState(null);

  const componentRef = useRef();

  useEffect(() => {
    async function fetchDetails() {
      const { data: muns } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/manage/municipios`,
      );
      setMuns(muns);
      setCurrentMun(muns[0].cd_mun);
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/desm/?options=municipios,${currentMun}`,
      );
      setData(data);
    }

    if (currentMun) fetchData();
  }, [currentMun]);

  const getMunName = () => {
    const found = muns.find(m => m.cd_mun === currentMun);
    return found ? found.nm_mun : '';
  }

  return (
    <>
      <Row>
        <Col>
          {currentMun && (
            <Form.Control
              as="select"
              size="lg"
              custom
              value={currentMun}
              onChange={(e) => setCurrentMun(e.target.value)}
            >
              {muns.map(({ cd_mun: id, nm_mun: nome }) => (
                <option value={id} key={id}>
                  {nome}
                </option>
              ))}
            </Form.Control>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <ChartContainer ref={componentRef}>
            <style type="text/css" media="print">{"\
              @page { size: 370mm 160mm;  }\
            "}</style>

            <PrintLabel className="only-print">
              <strong>Desmatamento por município</strong> ({getMunName()})
            </PrintLabel>

            {!data && <Loading>
              <Spinner className="graph" animation="border" role="status">
                <span className="sr-only">Carregando...</span>
              </Spinner>
            </Loading>}

            {data && (
              <ResponsiveBar
                className="chart"
                data={prepareDataDA(data)}
                keys={['desmatamento']}
                indexBy="ano"
                margin={{ top: 50, right: 120, bottom: 50, left: 60 }}
                padding={0.3}
                groupMode="grouped"
                colors={['#333', '#999']}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'desmatamento (hectares)',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableLabel={false}

              /* tooltip={({ id, value, color }) => (
                      <strong style={{ color }}>
                          {id}: {value}
                      </strong>
                  )} */
              />
            )}
          </ChartContainer>

          <ReactToPrint
            className="print-button"
            trigger={() => <PrintButton variant="outline-dark" block>
              <FontAwesomeIcon icon={faPrint} />
            </PrintButton>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    </>
  );
}

function DashDCF() {
  const [data, setData] = useState(null);

  const [cats, setCats] = useState([]);
  const [currentCat, setCurrentCat] = useState(null);

  const componentRef = useRef();

  useEffect(() => {
    async function fetchDetails() {
      const { data: cats } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/manage/catfundas`,
      );
      setCats(cats);
      setCurrentCat(cats[0].gid);
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/dash/desm/?options=catfundas,${currentCat}`,
      );
      setData(data);
    }

    if (currentCat) fetchData();
  }, [currentCat]);

  const getCatFundName = () => {
    const found = cats.find(c => String(c.gid) === String(currentCat));
    return found ? found.nome : '';
  }

  return (
    <>
      <Row>
        <Col>
          {currentCat && (
            <Form.Control
              as="select"
              size="lg"
              custom
              value={currentCat}
              onChange={(e) => setCurrentCat(e.target.value)}
            >
              {cats.map(({ gid, nome }) => (
                <option value={gid} key={gid}>
                  {nome}
                </option>
              ))}
            </Form.Control>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <ChartContainer ref={componentRef}>
            <style type="text/css" media="print">{"\
              @page { size: 370mm 160mm;  }\
            "}</style>

            <PrintLabel className="only-print">
              <strong>Desmatamento por categoria fundiária</strong> ({getCatFundName()})
            </PrintLabel>

            {!data && <Loading>
              <Spinner className="graph" animation="border" role="status">
                <span className="sr-only">Carregando...</span>
              </Spinner>
            </Loading>}

            {data && (
              <ResponsiveBar
                className="chart"
                data={prepareDataDA(data)}
                keys={['desmatamento']}
                indexBy="ano"
                margin={{ top: 50, right: 120, bottom: 50, left: 60 }}
                padding={0.3}
                groupMode="grouped"
                colors={['#333', '#999']}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'desmatamento (hectares)',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableLabel={false}

              /* tooltip={({ id, value, color }) => (
                      <strong style={{ color }}>
                          {id}: {value}
                      </strong>
                  )} */
              />
            )}
          </ChartContainer>

          <ReactToPrint
            className="print-button"
            trigger={() => <PrintButton variant="outline-dark" block>
              <FontAwesomeIcon icon={faPrint} />
            </PrintButton>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    </>
  );
}

function BigNumber({ number, text }) {
  return (
    <Col>
      <Card>
        <Card.Body>
          <BNNumber>{number}</BNNumber>
          <BNText>{text}</BNText>
        </Card.Body>
      </Card>
    </Col>
  );
}

/* Styles */

const StyledContainer = styled(Container)`
  margin: 20px auto;
  max-width: 90vw;

  @media (min-width: 1300px) {
    max-width: 1450px;
  }

  font-family: Helvetica, Arial, Verdana, sans-serif !important;
  & h2 {
    margin-bottom: 25px;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }
  & .card-title {
    font-weight: bold;
    color: #000;
    font-size: 16px;
    text-transform: uppercase !important;
  }
  & .card-text {
    line-height: 18px;
    font-size: 16px;
    text-align: justify;
    margin-bottom: 10px;
  }

  & .nav-link {
    color: #333;
  }
  & .nav-link.active {
    font-weight: bold;
    color: #333;
  }
`;

const BigNumbersContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;
const BNNumber = styled.div`
  font-size: 45px;
  font-weight: 600;
`;
const BNText = styled.span`
  margin-left: 10px;
`;
const TopMargedCard = styled(Card)`
  margin: 20px 0;
  font-family: Helvetica, Arial, Verdana, sans-serif !important;
`;

const TabContainer = styled.div`
  margin: 20px;
`;

const StyledPopover = styled(Popover)`
  max-width: 800px;
  & .popover-header {
    background-color: #000;
    color: #fff;
  }
`;

const ChartContainer = styled(Card)`
  margin-top: 10px;
  height: 500px;
  & .chart {
    height: 100%;
  }
`;

const PaginatorContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const ButtonInTab = styled(Button)`
  margin-left: 5px;
`;

const StyledTD = styled.td`
  text-align: center;
`;

const TabTitle = styled.div`
  font-size: 0.78em;
`;

const ScrolledData = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
  th {
    font-size: 0.9em;
  }
`;

const StyledTH = styled.th`
  cursor: pointer;
`;

const RankButtons = styled.td`
  button {
    margin-left: 10px;
  }
`;

const TRList = styled.tr`
  &.even {
    background-color: rgba(0, 0, 0, 0.05);
  }
  & > td {
    padding: 0;
  }
  & > td > div {
    margin: 10px;
  }
`;

const ScrolledData2 = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const StrippedTR = styled.tr`
  &.even {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ProducersListLabel = styled.h4`
  padding: 5px 0 0 10px;
`;

const Loading = styled.div`
  margin-top: 20px;

  & .graph {
    margin-left: 20px;
  }
`;

const PrintButton = styled(Button)`
  border-color: #dcdee0;
`;

const PrintLabel = styled.div`
  margin-left: 20px;
`;

export default Dashboard;
