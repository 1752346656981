import React, { Fragment } from 'react';

import { ButtonGroup, Button } from 'react-bootstrap';

import styled from 'styled-components';

import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FACTOR = 10;

function Paginator(props) {
    return <ButtonGroup size="sm">
        {!!props.pages && <Fragment>
            <Button variant="outline-dark" disabled={!props.hasPrevious} onClick={() => props.onChangePage(props.currentPage-1)}><FontAwesomeIcon icon={faBackward} /></Button>
            {!props.no_pagenumber && props.pages < 20 && <Pages {...props} />}
            {!props.no_pagenumber && props.pages >= 20 && <PagesComplex {...props} />}
            <Button variant="outline-dark" disabled={!props.hasNext} onClick={() => props.onChangePage(props.currentPage+1)}><FontAwesomeIcon icon={faForward} /></Button>
        </Fragment>}
    </ButtonGroup>;
}

function Pages(props) {
    return <Fragment>
        {range(1, props.pages).map(page => <Button variant="outline-dark" key={page} disabled={props.currentPage === page} onClick={() => props.onChangePage(page)}>{page}</Button>)}
    </Fragment>;
}
function PagesComplex(props) {
    let initial = props.currentPage - FACTOR;
    let final   = props.currentPage + FACTOR;

    if(props.currentPage <= FACTOR) {
        initial = 1;
        final   = 2 * FACTOR;
    } else if(props.currentPage >= props.pages - FACTOR) {
        initial = props.pages - (2 * FACTOR);
        final   = props.pages;
    }

    const pages = range(initial, final);

    return <Fragment>
        {!pages.includes(1) && <PageButton page={1} currentPage={props.currentPage} onChangePage={props.onChangePage} />}
        {!pages.includes(2) && <Button variant="outline-dark" disabled={true}>...</Button>}        

        {pages.map(page => <PageButton key={page} page={page} currentPage={props.currentPage} onChangePage={props.onChangePage} />)}

        {!pages.includes(props.pages-1) && <Button variant="outline-dark" disabled={true}>...</Button>}
        {!pages.includes(props.pages) && <PageButton page={props.pages} onChangePage={props.onChangePage} />}
    </Fragment>;
}
function PageButton(props) {
    return <StyledButton variant="outline-dark" disabled={props.currentPage === props.page} onClick={() => props.onChangePage(props.page)}>{props.page}</StyledButton>
}

const range = (start, stop, step = 1) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

const StyledButton = styled(Button)`
    font-weight: ${props => props.disabled ? "bold" : "normal"};
    font-size: ${props => props.disabled ? "1.3em" : "1em"};
`;

export default Paginator;