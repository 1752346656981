import axios from 'axios';

class UserProfile {
    constructor() {
        if(localStorage.getItem('token')) {

            this.currentUserStatus = 'logged';
            this.token = localStorage.getItem('token');
            this.user = JSON.parse(localStorage.getItem('user'));

        } else this.currentUserStatus = 'non-logged';
    }

    get isLogged() {
        return this.currentUserStatus !== 'non-logged';
    }

    async login(email, password) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}id-service/login`, {
                email,
                password,
            });

            const { user, token } = response.data;

            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));

            this.token = token;
            this.user = user;

            return {
                success: true,
                user,
                token,
            }
        } catch (e) {
            return {
                success: false,
                error: e.message,
            }
        }

    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }
}

const singletonInstance = new UserProfile();
// TODO: study -> Object.freeze(singletonInstance);

export default singletonInstance;