import React, { useState } from 'react';

import UserProfile from './UserProfile';

import MainBar from './components/MainBar';
import Footer from './components/Footer';

import Home from './components/Home';
import Dashboard from './components/Dashboard';
import ManageProducers from './components/ManageProducers';
import ManageAssociations from './components/ManageAssociations';
import ManageMicro from './components/ManageMicro';
import ManageCosts from './components/ManageCosts';
import Login from './components/Login';

import { Container, Toast } from 'react-bootstrap';

import styled from 'styled-components';

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Switch,
    Route,
    useHistory,
    Redirect,
} from "react-router-dom";

function App() {
    let history = useHistory();

    const [isLogged, setLogged] = useState(UserProfile.isLogged);
    const [showToast, setShowToast] = useState(false);

    const login = async (email, password) => {

        const result = await UserProfile.login(email, password);

        if (result.success) {
            setLogged(true);
            /* TODO: redirect? */
        } else {
            setShowToast(true);
        }
    }

    const logout = () => {
        UserProfile.logout();
        setLogged(false);
        history.push('/');
    }

    return (
        <>
            <NoMarginContainer fluid={true}>
                <MainBar
                    user={UserProfile.user}
                    isLogged={isLogged}
                    onLogout={logout}
                />
                <div className="content">
                    <Switch>

                        {isLogged && <Route path="/home"><Home /></Route>}
                        {isLogged && <Route path="/produtores"><ManageProducers /></Route>}
                        {isLogged && <Route path="/associacoes"><ManageAssociations /></Route>}
                        {isLogged && <Route path="/microrregioes"><ManageMicro /></Route>}
                        {isLogged && <Route path="/custos"><ManageCosts /></Route>}
                        {isLogged && <Route path="/painel"><Dashboard /></Route>}
                        {!isLogged && <Route path="/login"><Login onLogin={login} /></Route>}
                        <Route path="/">
                            <Redirect to={isLogged ? "/painel" : '/login'} />
                        </Route>

                    </Switch>
                </div>
                <Footer />                

                <StyledToast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <strong className="mr-auto">Erro</strong>                        
                    </Toast.Header>
                    <Toast.Body>Login ou Senha incorretos!</Toast.Body>
                </StyledToast>
            </NoMarginContainer>            
        </>
    );
}

const NoMarginContainer = styled(Container)`
    padding-left: 0;
    padding-right: 0;
`;

const StyledToast = styled(Toast)`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;

    & .toast-header {
        background-color: red;
        color: #fff;
    }

    & .toast-header strong {
        margin: 3px 4px;
    }
`;

export default App;