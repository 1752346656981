import React, { useState, useEffect } from 'react';

import axios from 'axios';

import moment from 'moment';

import { Container, Col, Row } from 'react-bootstrap';

import logoImas from '../../images/imaflora_recorte.png';
import logoIdesam from '../../images/idesam_recorte.png';
import styled from 'styled-components';

function Footer() {
    const [lastUpdate, setLastUpdate] = useState(null);

    useEffect(() => {
        async function getLastUpdate() {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER}geo-service/config/last_db_update`);
            if(data) setLastUpdate(moment(data['last_db_update']).format('DD/MM/YYYY'));
        }
        getLastUpdate();
    },[]);

    return <SiteFooter>
        <FooterRow>
            <Col sm={2}><LogoIMAFLORA src={logoImas} alt="IMAFLORA Logo" /></Col>
            <Col sm={2}><LogoIDESAM src={logoIdesam} alt="IDESAM Logo" /></Col>
            <Col sm={2}><LastUpdate><span>Dados atualizados em:</span> {lastUpdate}</LastUpdate></Col>
        </FooterRow>
    </SiteFooter>;
}

const SiteFooter = styled(Container)`
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: 1300px) {
        max-width: 1450px;
    }
`;

const FooterRow = styled(Row)`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-top: solid 1px #ccc;
    margin: 5px;
    padding: 20px 0;
    font-family: Helvetica,Arial,Verdana,sans-serif !important;
    font-size: 0.9em;
`;

const LogoIDESAM = styled.img`
    height: 25px;
`;
const LogoIMAFLORA = styled.img`
    height: 35px;
`;
const LastUpdate = styled.div`
    color: #999;
    font-size: 0.8em;
    span {
        font-weight: bold; 
    }
`;

export default Footer;