import React, { useState, Fragment, useEffect, useRef } from 'react';

import axios from 'axios';

import styled from 'styled-components';
import { CircleMarker, Popup } from 'react-leaflet';

import { getRiscoEscala } from '../../utils';

import { Table } from 'react-bootstrap';

function Producers(props) {
  const [producers, setProducers] = useState([]);

  useEffect(() => {
    loadProducers();
  }, [props.mode]);

  const loadProducers = async () => {
    setProducers([]);
    // console.log('loadProducers');
    // TODO: TRY/CATCH
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}geo-service/producers`,
    );
    setProducers(data);
  };

  return (
    <Fragment>
      {producers.map((producer) => {
        const { gid, geojson, assoc: associacao } = producer;
        if (!geojson) return null;
        return (
          <CircleMarker
            key={gid}
            center={[
              geojson.geometry.coordinates[1],
              geojson.geometry.coordinates[0],
            ]}
            fillColor={`#${associacao?.color || '000000'}`}
            color={props.style.color(
              props.mode,
              getRiscoEscala(geojson.properties.risco),
            )}
            weight={props.style.weight(props.mode)}
            radius={props.style.radius}
            opacity={props.style.opacity}
            fillOpacity={props.style.fillOpacity}
          >
            <PanelPopupProducer data={geojson.properties} />
          </CircleMarker>
        );
      })}
    </Fragment>
  );
}

const PanelPopupProducer = ({ data }) => {
  const { gid, nome, seringal, municipio } = data;

  const [family, _family] = useState(null);

  const pop = useRef();

  useEffect(() => {
    _family(null);
  }, [gid])

  const retrieveFamily = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}geo-service/producers/${gid}/family`,
    );

    _family(data);
  }

  const handleClick = (f) => {
    pop.current.leafletElement.options.leaflet.map.closePopup();
    pop.current.leafletElement.options.leaflet.map.flyTo([f.lat, f.long]);
  }

  return (
    <StyledPopup
      onOpen={retrieveFamily}
      ref={pop}
    >
      <div className="header">PRODUTOR</div>
      <Table className="body">
        <tbody>
          <tr>
            <td>
              <strong>NOME</strong>
            </td>
            <td>{nome}</td>
          </tr>
          {seringal && (
            <tr>
              <td>
                <strong>SERINGAL</strong>
              </td>
              <td>{seringal}</td>
            </tr>
          )}
          {municipio && (
            <tr>
              <td>
                <strong>MUNICÍPIO</strong>
              </td>
              <td>{municipio}</td>
            </tr>
          )}
          {!family && (
            <tr>
              <td colSpan={2}>
                Buscando por familiares...
              </td>
            </tr>
          )}
          {!!family && !!family.length && (
            <tr>
              <td colSpan={2}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div><strong>FAMILIARES</strong></div>
                  <div>
                    <ul style={{
                      padding: '0 15px',
                      listStyleType: 'square',
                    }}>
                      {family.map(f => (<Fragment key={f.id} >
                        {f.status === 'ativo' && <li style={{ cursor: 'pointer' }} onClick={() => handleClick(f)}><span>{f.outro_nome}</span> ({f.relacao_nome})</li>}
                        {f.status !== 'ativo' && <li><span>{f.outro_nome}</span> ({f.relacao_nome})</li>}
                      </Fragment>))}
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </StyledPopup>
  );
};
const StyledPopup = styled(Popup)`
  & .leaflet-popup-content-wrapper {
    border-radius: 5px !important;
  }
  & .leaflet-popup-content {
    margin: 0;
  }
  & .header {
    margin: 13px 1px 1px 1px;
    padding: 5px;
    background-color: #333;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }
  & a.leaflet-popup-close-button {
    color: #333 !important;
    background-color: #fff;
    padding: 1px 0 0 0;
  }
`;

export default Producers;
