/* Auxiliary functions */
import { producersConf } from './config/layerConfig';

export function getAssocColor(key) {
  return producersConf.style.fillColor(key, 0);
}
export function getMicroColor(key) {
  return producersConf.style.fillColorMicro(key);
}

export function prepareDataDAT(data) {
  let keys = [];
  let groupedByYear = {};

  data.map(({ id, ano, nome, area_ha, color }) => {
    if (!groupedByYear[ano]) groupedByYear[ano] = { ano };
    groupedByYear[ano][nome] = Math.round(area_ha * 100) / 100;

    if (!keys.find(k => k.nome === nome)) keys.push({ id, nome, color });

    return true;
  });

  const defs = keys.map((key, idx) => ({
    id: `_${idx}Color`,
    type: 'patternLines',
    background: 'inherit',
    color: `#${key.color || '000000'}`,
    lineWidth: 2,
    spacing: 1,
  }));

  const fill = keys.map((key, idx) => ({
    match: (d) => {
      return d.data.id === key.nome;
    },
    id: `_${idx}Color`,
  }));

  return {
    keys: keys.map(({ nome }) => nome),
    values: Object.values(groupedByYear),
    defs,
    fill,
  };
}

export function prepareDataDashDpA(data) {
  let keys = [];
  let groupedByAssoc = {};

  data.map(({ associacao, microrregiao, area_ha }) => {
    if (!groupedByAssoc[associacao])
      groupedByAssoc[associacao] = { associacao };
    groupedByAssoc[associacao][microrregiao] = Math.round(area_ha * 100) / 100;

    if (!keys.includes(microrregiao)) keys.push(microrregiao);

    return true;
  });

  const defs = keys.map((key, idx) => ({
    id: `_${idx}Color`,
    type: 'patternLines',
    background: 'inherit',
    color: getMicroColor(key),
    lineWidth: 2,
    spacing: 1,
  }));

  const fill = keys.map((key, idx) => ({
    match: (d) => {
      return d.data.id === key;
    },
    id: `_${idx}Color`,
  }));

  return {
    keys,
    values: Object.values(groupedByAssoc),
    defs,
    fill,
  };
}

export function prepareDataDA(data) {
  const preparedData = [];

  data.map(({ ano, sum_area_ha, sum_accum }) => {
    preparedData.push({
      ano,
      desmatamento: Math.round(sum_area_ha * 100) / 100,
      'desmatamento acum.': Math.round(sum_accum * 100) / 100,
    });
    return true;
  });

  return preparedData;
}

export function getRiscoEscala(risco) {
  switch (risco) {
    case 3:
      return 'BAIXO';
    case 1:
      return 'ALTO';
    default:
      return 'BAIXO';
  }
}
