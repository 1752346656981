import React, { createRef, useState, useEffect, useCallback } from 'react';

import axios from 'axios';

import L from 'leaflet';
import {
  Map,
  TileLayer,
  ScaleControl,
  Popup,
  LayersControl,
  LayerGroup,
  WMSTileLayer,
  Pane,
} from 'react-leaflet';
import Control from 'react-leaflet-control';

import moment from 'moment';
import numeral from 'numeral';

import styled from 'styled-components';

import AsyncSelect from 'react-select/async';

import Producers from '../Producers';
import AreasInfluencia from '../AreasInfluencia';
import Legend from '../Legend';
import BetterWMS from '../BetterWMS';

import {
  producersConf,
  areaConf,
  protecteAreasConf,
  municipalityConf,
  hidroConf,
} from '../../config/layerConfig';

import {
  Table,
  Button,
  Jumbotron,
  Row,
  Col,
  Card,
  Tabs,
  Tab,
} from 'react-bootstrap';

import { withRouter } from 'react-router';

import { ResponsiveBar } from '@nivo/bar';

import 'rc-slider/assets/index.css';
import { Range } from 'rc-slider';

import { debounce } from 'lodash';

import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { prepareDataDA, getRiscoEscala } from '../../utils';

import Paginator from '../Paginator';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

let position = [-9.5, -70];
let zoom = 8;

let memCores = 0;
let memBaseMap = 'Mapa Base';
let memOverlays = {
  'Área de Influência': true,
  Fornecedores: true,
  Desmatamento: true,
  'Áreas Protegidas': false,
  Municipios: true,
  Hidrografia: true,
};

/* TODO: remover esta droga...rs */
/* let originalPos = position;
let originalZoom = zoom; */

const firstYear = 2008;
const thisYear = moment().get('year');

function Home({ location }) {
  const mapRef = createRef();

  const [prodLeg, handleProdLeg] = useState(true);
  const [areaLeg, handleAreaLeg] = useState(true);

  const [hidroLeg, handleHidroLeg] = useState(false);
  const [defoLeg, handleDefoLeg] = useState(true);
  const [pasLeg, handlePasLeg] = useState(false);
  const [munLeg, handleMunLeg] = useState(true);

  const [latLngPanel, setLatLngPanel] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [showPanel, setShowPanel] = useState(false);

  const [gidSummary, setGIDSummary] = useState(null);

  const [flyToRegion] = useState(
    location && location.state ? location.state.flyToRegion : null,
  );

  const [cores, setCores] = useState(memCores);

  const [desmYearS, setDesmYearS] = useState(firstYear);
  const [desmYearE, setDesmYearE] = useState(thisYear);

  const [fornecedorSearchValue, _fornecedorSearchValue] = useState(null);

  const [associations, setAssociations] = useState(null);

  useEffect(()=>{
    async function getAssociations() {
      const { data: assocs } = await axios.get(
        `${process.env.REACT_APP_SERVER}geo-service/associations`,
      );
      console.log(assocs)

      setAssociations(assocs.map(a => ({
        id: a.id,
        name: a.nome,
        color: "#000",
        fillColor: `#${a.color}`,
        circle: true,
      })));

    }
    getAssociations();
  },[])

  /* Memory strategy */
  useEffect(() => {
    memCores = cores;
  }, [cores]);

  /* useEffect(()=>{
        console.log('flyToRegion', flyToRegion);
        if(!flyToRegion && mapRef.current) {
            setTimeout(() => {
                mapRef.current.leafletElement.setZoom(originalZoom);
                mapRef.current.leafletElement.panTo(originalPos);
            }, 200);
        }
    }, [flyToRegion, mapRef]); */

  useEffect(() => {
    if (!fornecedorSearchValue || !mapRef) return;

    const coord = fornecedorSearchValue?.geojson?.geometry?.coordinates;
    if (coord && coord.length === 2 && mapRef && mapRef.current)
      mapRef.current.leafletElement.flyTo([coord[1], coord[0]], 18);
  }, [fornecedorSearchValue, mapRef]);

  const handleMove = () => {
    if (mapRef && mapRef.current) {
      const { lat, lng } = mapRef.current.leafletElement.getCenter();
      position = [lat, lng];
    }
  };
  const handleZoom = () => {
    if (mapRef && mapRef.current) {
      zoom = mapRef.current.leafletElement.getZoom();
    }
  };

  const handleGetFeatureInfo = (data, latLng) => {
    console.log(data)
    setShowPanel(false);
    if (
      data.features &&
      data.features[0] &&
      data.features[0].properties &&
      latLng
    ) {
      const { ano, area_ha, classe } = data.features[0].properties;

      setPanelData({ ano, area_ha, classe });
      setLatLngPanel(latLng);
      setShowPanel(true);
    }
  };

  const handleWMSPanelClose = () => {
    setShowPanel(false);
    setLatLngPanel(null);
    setPanelData(null);
  };

  const handleInfo = (gid) => {
    setGIDSummary(gid);
  };

  const handleFly = (gid, bounds) => {
    if (mapRef.current) {
      setTimeout(() => mapRef.current.leafletElement.fitBounds(bounds), 200);
      setTimeout(() => setGIDSummary(gid), 500);
    }
  };

  const handleDesmFilterChange = useCallback(
    debounce((value) => {
      setDesmYearS(value[0]);
      setDesmYearE(value[1]);
    }, 500),
    [],
  );

  /* console.log(`ano between '${desmYearS}' and '${desmYearE}'`); */

  const promiseOptions = (inputValue, callback) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER}geo-service/producers/search/?filter=${inputValue}`,
      )
      .then(function ({ data }) {
        callback(data);
      });
  };

  const handleFornecedorSeachChange = (selectedOption) => {
    console.log({selectedOption})
    _fornecedorSearchValue(selectedOption);
  }

  return (
    <HomeContainer>
      <Sidebar
        gid={gidSummary}
        show={gidSummary}
        onClose={() => setGIDSummary(null)}
      />

      <MapContainer>
        <Map
          center={position}
          zoom={zoom}
          ref={mapRef}
          /* maxZoom={16} */
          minZoom={5}
          // maxBounds={[
          //   [-5, -78],
          //   [-13, -62],
          // ]}
          onMoveend={handleMove}
          onZoomend={handleZoom}
          onBaselayerchange={(e) => (memBaseMap = e.name)}
          onOverlayadd={(e) => (memOverlays[e.name] = true)}
          onOverlayremove={(e) => (memOverlays[e.name] = false)}
        >
          <LayersControl position="bottomleft" collapsed={false}>
            <LayersControl.BaseLayer
              name="Satélite"
              checked={memBaseMap === 'Satélite'}
            >
              <LayerGroup>
                <TileLayer
                  attribution="Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />

                <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}" />
              </LayerGroup>
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer
              name="Planet"
              checked={memBaseMap === 'Satélite Planet'}
            >
              <LayerGroup>
                <TileLayer
                  attribution="Imagery © 2020 Planet Labs Inc."
                  url={`https://{s}.planet.com/basemaps/v1/planet-tiles/planet_medres_normalized_analytic_2020-09_mosaic/gmap/{z}/{x}/{y}.png?api_key=${process.env.REACT_APP_PLANET_KEY}`}
                  subdomains={['tiles0', 'tiles1', 'tiles2', 'tiles3']}
                />

                <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}" />
              </LayerGroup>
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer
              name="Mapa Base"
              checked={memBaseMap === 'Mapa Base'}
            >
              <LayerGroup>
                <TileLayer
                  attribution='<a href="http://cartodb.com/attributions">CartoDB</a>'
                  url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}{r}.png"
                />
                <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}" />
              </LayerGroup>
            </LayersControl.BaseLayer>

            <LayersControl.Overlay
              name="Área de Influência"
              checked={memOverlays['Área de Influência']}
            >
              <CustomPane className="area-influencia"></CustomPane>
              <LayerGroup
                onAdd={() => handleAreaLeg(true)}
                onRemove={() => handleAreaLeg(false)}
              >
                <AreasInfluencia
                  mode={cores}
                  Fornecedores
                  flyToRegion={flyToRegion}
                  style={areaConf.style}
                  onInfo={handleInfo}
                  fly={handleFly}
                  microId={gidSummary}
                />
              </LayerGroup>
            </LayersControl.Overlay>

            <LayersControl.Overlay
              name="Fornecedores"
              checked={memOverlays['Fornecedores']}
            >
              <CustomPane className="fornecedores">
                <LayerGroup
                  onAdd={() => handleProdLeg(true)}
                  onRemove={() => handleProdLeg(false)}
                >
                  <Producers
                    mode={cores}
                    style={producersConf.style}
                    microId={gidSummary}
                  />
                </LayerGroup>
              </CustomPane>
            </LayersControl.Overlay>

            <LayersControl.Overlay
              name="Hidrografia"
              checked={memOverlays['Hidrografia']}
            >
              <CustomPane className="hidrografia">
                <WMSTileLayer
                  url={`${process.env.REACT_APP_GEOSERVER_URL}vft/wms`}
                  layers="vft:hidrografia"
                  format="image/png"
                  transparent={true}
                  onAdd={() => handleHidroLeg(true)}
                  onRemove={() => handleHidroLeg(false)}
                />
              </CustomPane>
            </LayersControl.Overlay>

            <LayersControl.Overlay
              name="Desmatamento"
              checked={memOverlays['Desmatamento']}
            >
              <BetterWMS
                url={`${process.env.REACT_APP_GEOSERVER_URL}vft/wms`}
                layers="vft:desm_agregado"
                format="image/png"
                transparent={true}
                cql_filter={`ano between '${desmYearS}' and '${desmYearE}'`}
                onAdd={() => handleDefoLeg(true)}
                onRemove={() => handleDefoLeg(false)}
                onGetFeatureInfo={handleGetFeatureInfo}
              />
            </LayersControl.Overlay>

            <LayersControl.Overlay
              name="Áreas Protegidas"
              checked={memOverlays['Áreas Protegidas']}
            >
              <WMSTileLayer
                url={`${process.env.REACT_APP_GEOSERVER_URL}vft/wms`}
                layers="vft:aps_limites"
                format="image/png"
                transparent={true}
                onAdd={() => handlePasLeg(true)}
                onRemove={() => handlePasLeg(false)}
              />
            </LayersControl.Overlay>

            <LayersControl.Overlay
              name="Municipios"
              checked={memOverlays['Municipios']}
            >
              <WMSTileLayer
                url={`${process.env.REACT_APP_GEOSERVER_URL}vft/wms`}
                layers="vft:municipios"
                format="image/png"
                transparent={true}
                onAdd={() => handleMunLeg(true)}
                onRemove={() => handleMunLeg(false)}
              />
            </LayersControl.Overlay>
          </LayersControl>

          <Control position="bottomleft" style={{ width: '200px' }}>
            <ControlCard>
              <ControlDiv>
                <strong>Classificar por</strong>
              </ControlDiv>
              <ControlDiv>
                <input
                  type="radio"
                  name="cores"
                  value={0}
                  checked={cores === 0}
                  onChange={() => setCores(0)}
                />{' '}
                Associações / Cooperativas
              </ControlDiv>
              <ControlDiv>
                <input
                  type="radio"
                  name="cores"
                  value={1}
                  checked={cores === 1}
                  onChange={() => setCores(1)}
                />{' '}
                Risco
              </ControlDiv>
            </ControlCard>
          </Control>

          <ScaleControl position="topleft" />

          <LegendContainer>
            {!!associations && <Legend
              show={prodLeg}
              label={'Fornecedores'}
              features={associations}
            />}

            <Legend
              show={areaLeg}
              label={'Área de Influência'}
              features={areaConf.legend}
            />

            <Legend show={hidroLeg} label={''} features={hidroConf.legend} />

            <Legend
              show={defoLeg}
              label={'Desmatamento'}
              png={
                `${process.env.REACT_APP_GEOSERVER_URL}wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LEGEND_OPTIONS=dpi:100;columns:2&LAYER=vft:desm_agregado`
              }
            />

            <Legend
              show={pasLeg}
              label={'Áras Protegidas'}
              features={protecteAreasConf.legend}
            />

            <Legend
              show={munLeg}
              label={''}
              features={municipalityConf.legend}
            />
          </LegendContainer>

          {showPanel && latLngPanel && (
            <PanelPopup
              latLng={latLngPanel}
              onClose={handleWMSPanelClose}
              data={panelData}
            />
          )}
        </Map>
        <YearFilterContainer className="filtro_demat">
          <div className="title">Filtro de desmatamento por ano</div>
          <DesmatamentoYearFilter onChange={handleDesmFilterChange} />
        </YearFilterContainer>

        <ProducerFilterContainer className="filtro_prod">
          <AsyncSelect
            loadOptions={promiseOptions}
            isClearable
            noOptionsMessage={() => 'Nenhum fornecedor encontrado!'}
            loadingMessage={() => 'Buscando...'}
            placeholder={'Digite o nome do fornecedor...'}
            value={fornecedorSearchValue}
            onChange={handleFornecedorSeachChange}
          />
        </ProducerFilterContainer>
      </MapContainer>
    </HomeContainer>
  );
}

const PanelPopup = (props) => {
  const { ano, area_ha, classe } = props.data;

  return (
    <StyledPopup
      position={[props.latLng.lat, props.latLng.lng]}
      onClose={props.handleWMSPanelClose}
    >
      <div className="header">{classe}</div>
      <Table className="body">
        <tbody>
          <tr>
            <td>
              <strong>ANO</strong>
            </td>
            <td>{ano}</td>
          </tr>
          <tr>
            <td>
              <strong>ÁREA</strong>
            </td>
            <td>{Math.round(parseFloat(area_ha) * 100) / 100} ha</td>
          </tr>
        </tbody>
      </Table>
    </StyledPopup>
  );
};

function Sidebar({ gid, show, onClose }) {
  const [assocData, setAssocData] = useState(null);
  const [microName, setMicroName] = useState(null);
  const [microArea, setMicroArea] = useState(null);
  const [somaMovel, setSomaMovel] = useState(null);

  const [producersData, setProducersData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [tab, setTab] = useState('desmatamento');

  const [paginatorData, setPaginatorData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);

    if (gid) {
      async function fetchRiscoData() {
        setAssocData(null);
        const {
          data: { assocs, micro_nome, micro_area, soma_movel },
        } = await axios.get(
          `${process.env.REACT_APP_SERVER}geo-service/dash/areas/properties/${gid}`,
        );
        setAssocData(assocs.join(','));
        setMicroName(micro_nome);
        setMicroArea(micro_area);
        setSomaMovel(soma_movel);
      }
      async function fetchChartData() {
        setChartData(null);
        const { data } = await axios.get(
          `${process.env.REACT_APP_SERVER}geo-service/dash/areas/desm/${gid}`,
        );
        setChartData(data);
      }

      fetchRiscoData();
      fetchChartData();
    }
  }, [gid]);

  useEffect(() => {
    if (gid) {
      async function fetchProducersData() {
        setProducersData(null);
        const {
          data: { rows, pages, hasPrevious, hasNext },
        } = await axios.get(
          `${process.env.REACT_APP_SERVER}geo-service/dash/areas/producer/${gid}?page=${currentPage}`,
        );
        setProducersData(rows);

        setPaginatorData({ pages, hasPrevious, hasNext });
      }

      fetchProducersData();
    }
  }, [gid, currentPage]);

  return (
    <SidebarContainer className={show ? 'open' : ''}>
      <SliderCloseButton variant="outline-dark" onClick={onClose}>
        x
      </SliderCloseButton>
      <Jumbotron>
        <h4>
          Resumo sobre a microrregião:
          {!microName && <RiscoSpan>carregando...</RiscoSpan>}
          {microName && <RiscoSpan>{microName}</RiscoSpan>}
        </h4>
        <div>
          <div>
            Associações / Cooperativas:
            {!assocData && <RiscoSpan>carregando...</RiscoSpan>}
            {assocData && <RiscoSpan>{assocData}</RiscoSpan>}
          </div>
          <div>
            Área total:
            {!microArea && <RiscoSpan>carregando...</RiscoSpan>}
            {microArea && (
              <RiscoSpan>
                {numeral(parseFloat(microArea)).format('0,0.00')} ha
              </RiscoSpan>
            )}
          </div>
          <div>
            Desmat. acum. (últimos 3 anos):
            {!somaMovel && <RiscoSpan>carregando...</RiscoSpan>}
            {somaMovel && (
              <RiscoSpan>
                {numeral(parseFloat(somaMovel)).format('0,0.00')} ha
              </RiscoSpan>
            )}
          </div>
        </div>
      </Jumbotron>

      <Tabs activeKey={tab} onSelect={(k) => setTab(k)}>
        <Tab eventKey="desmatamento" title="Desmatamento">
          <Row>
            <Col>
              <AIContainer>
                {!chartData && <div>Carregando gráfico...</div>}
                <ChartContainer>
                  {chartData && (
                    <ResponsiveBar
                      className="chart"
                      data={prepareDataDA(chartData)}
                      keys={['desmatamento']}
                      indexBy="ano"
                      margin={{ top: 50, right: 10, bottom: 50, left: 50 }}
                      padding={0.3}
                      groupMode="grouped"
                      colors={['#333', '#999']}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'desmatamento (hectares)',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      enableLabel={false}

                      /* tooltip={({ id, value, color }) => (
                              <strong style={{ color }}>
                                  {id}: {value}
                              </strong>
                          )} */
                    />
                  )}
                </ChartContainer>
              </AIContainer>
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="fornecedores" title="Fornecedores">
          <Row>
            <Col>
              <AIContainer>
                <TableContainer>
                  {!producersData && (
                    <div>Carregando dados de Fornecedores...</div>
                  )}
                  {producersData && (
                    <>
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>Localidade</th>
                            <th>Seringal</th>
                            <th>Risco</th>
                          </tr>
                        </thead>

                        <tbody>
                          {producersData.map(
                            ({ gid, nome, municipio, seringal, risco }) => (
                              <tr key={gid}>
                                <td>{nome}</td>
                                <td>{municipio}</td>
                                <td>{seringal}</td>
                                <td>{getRiscoEscala(risco)}</td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </Table>

                      {paginatorData && paginatorData.pages > 1 && (
                        <PaginatorContainer>
                          <Paginator
                            {...paginatorData}
                            currentPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                          />
                        </PaginatorContainer>
                      )}
                    </>
                  )}
                </TableContainer>
              </AIContainer>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </SidebarContainer>
  );
}

function DesmatamentoYearFilter({ onChange }) {
  const [value, setValue] = useState([firstYear, thisYear]);

  const [marks, setMarks] = useState({});
  useEffect(() => {
    let marks = {};
    for (let ano = firstYear; ano <= thisYear; ano++) marks[ano] = ano;

    setMarks(marks);
  }, []);

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <Range
      min={firstYear}
      max={thisYear}
      marks={marks}
      step={null}
      onChange={handleChange}
      value={value}
    />
  );
}

function LegendContainer({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <LegendContainerStyles>
      <div
        className={`control ${open ? 'active' : 'inactive'}`}
        onClick={() => setOpen(!open)}
      >
        <div>Legendas</div>{' '}
        <div style={{ marginTop: '1px' }}>
          {open ? (
            <FontAwesomeIcon icon={faMinusSquare} />
          ) : (
            <FontAwesomeIcon icon={faPlusSquare} />
          )}
        </div>
      </div>
      <div className={`body ${open ? 'active' : 'inactive'}`}>{children}</div>
    </LegendContainerStyles>
  );
}

/* Styles */

const LegendContainerStyles = styled.div`
  z-index: 5000;
  color: #333;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  right: 0px;
  width: auto;
  margin: 0px 10px 10px 0px;
  .control {
    background: #666;
    color: #fff;
    cursor: pointer;
    padding: 2px 6px;
    font-weight: bold;
    font-size: 1.25em;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    justify-content: space-between;

    &.inactive {
      background: #fff;
      color: rgb(33, 37, 41);
    }
  }
  .body {
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    padding: 6px 10px 0px 6px;
    max-height: 70vh;

    &.inactive {
      height: 0px;
      padding: 0px;
    }
  }
`;

const StyledPopup = styled(Popup)`
  & .leaflet-popup-content-wrapper {
    border-radius: 5px !important;
  }
  & .leaflet-popup-content {
    margin: 0;
  }
  & .header {
    margin: 13px 1px 1px 1px;
    padding: 5px;
    background-color: #333;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }
  & a.leaflet-popup-close-button {
    color: #333 !important;
    background-color: #fff;
    padding: 1px 0 0 0;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 115px);
  background: #f1f4f5;
`;
const MapContainer = styled.div`
  flex-grow: 1;
  z-index: 1;
  background-color: #d3d8d4;
  position: relative;
  > div:not(.filtro_demat):not(.filtro_prod) {
    width: 100%;
    height: 100%;
  }
`;
const CustomPane = styled(Pane)`
  &.hidrografia {
    z-index: 410;
  }
  &.area-influencia {
    z-index: 420;
  }
  &.fornecedores {
    z-index: 430;
  }
`;

const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  width: 640px;
  height: 100vh;
  background-color: #fff;
  z-index: 5000;
  left: -640px;

  transition-property: left;
  transition-duration: 0.5s;

  -webkit-box-shadow: 7px 0px 11px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 0px 11px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 0px 11px -2px rgba(0, 0, 0, 0.75);

  overflow-y: auto;
  overflow-x: hidden;

  &.open {
    left: 0px;
  }
`;
const SliderCloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const AIContainer = styled.div`
  margin: 0 10px;
`;

const ChartContainer = styled(Card)`
  margin-top: 10px;
  height: 400px;
  & .chart {
    height: 100%;
  }
`;
const TableContainer = styled(Card)`
  margin-top: 10px;
`;

const RiscoSpan = styled.span`
  margin-left: 10px;
`;

const ControlCard = styled(Card)`
  padding: 7px;
`;

const ControlDiv = styled.div`
  margin: 5px 0;
`;

const YearFilterContainer = styled.div`
  width: 500px;
  position: absolute;
  bottom: 20px;
  z-index: 4800;
  left: 150px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 25px 30px 25px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  .title {
    text-align: left;
    font-size: 10pt;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: -15px;
  }
`;
const ProducerFilterContainer = styled.div`
  width: 400px;
  position: absolute;
  top: 20px;
  z-index: 4800;
  right: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
`;

const PaginatorContainer = styled.div`
  width: 100%;
  text-align: right;
`;

export default withRouter(Home);
