import React, { Fragment } from 'react';

import styled, { ThemeProvider } from 'styled-components';

function Legend(props) {
  return <Fragment>
    <LegendWrap>
      {props.show && 
        props.png ? 
          <LegendLabel> {props.label}
            <LegendItemsList>
              <img src={props.png} alt={`${props.label} legend`}></img>
            </LegendItemsList>
          </LegendLabel>
        : 
          <Fragment>
            {props.show && 
              <LegendLabel> {props.label} 
                <LegendItems features={props.features} />
              </LegendLabel>
            }
          </Fragment>
      }
    </LegendWrap>
  </Fragment>
}

function LegendItems(props) {
  return <Fragment>
     {props.features.map(feature => {
      return (
          <LegendItemsList key={feature.name}>
            <ThemeProvider theme={{ fillColor: feature.fillColor, color: feature.color, circle: feature.circle }}>
              <LegendIcon></LegendIcon>
              <LegendName>{feature.name}</LegendName>
            </ThemeProvider>
          </LegendItemsList>
      )
    })}
  </Fragment>
}

/* Styles */
const LegendWrap = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
`
const LegendLabel = styled.span`
  text-align: left;
  font-size: 11pt;
  font-weight: bold;
  margin-bottom: 10px;
`
const LegendItemsList = styled.li`
  margin: 0px;
`
const LegendIcon = styled.i`
  width: 15px;
  height: 15px;
  float: left;
  margin-right: 8px;
  background-color: ${props => props.theme.fillColor};
  border: solid 1px ${props => props.theme.color};
  border-radius: ${props => {
    if (props.theme.circle) {
      return '10px'
    }
    else {return ''}
  }}
`
const LegendName = styled.span`
  font-size: 10pt;
`

export default Legend